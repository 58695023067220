import React from "react";

class Circle extends React.Component {
  render() {
    let circleStyle = {
      padding: 0,
      margin: 0,
      display: "inline-block",
      backgroundColor: this.props.bgColor,
      borderRadius: "50%",
      width: this.props.circleSize,
      height: this.props.circleSize
    };
    return <div style={circleStyle}></div>;
  }
}

export default Circle;
