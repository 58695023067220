export const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  padding: 8 * 2,
  margin: `0 0 ${8}px 0`,
  background: isDragging ? "lightgreen" : "#a6a6a6",
  ...draggableStyle
});

export const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "lightblue" : "lightgrey",
  padding: 8,
  width: 250,
  margin: "auto"
});
