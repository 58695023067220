import React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Button, Dialog } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

const LogsActionDialog = ({ open, onClose, log }) => (
  <Dialog open={open} onClose={onClose} maxWidth="xl">
    <DialogTitle
      style={{
        backgroundColor: "#3f51b5",
        textAlign: "center"
      }}
    >
      <div style={{ color: "white" }}> {log.logDescription}</div>
    </DialogTitle>
    <DialogContent>
      <br />
      <div>Status: {log.response.StatusCode}</div>
      <br />
      <div style={{ whiteSpace: "pre-line" }}>
        Résultats: {log.response.Payload}
      </div>
    </DialogContent>
    <DialogActions>
      <Button
        onClick={onClose}
        variant={"contained"}
        style={{
          backgroundColor: "#32CD32"
        }}
      >
        <div
          style={{
            color: "white"
          }}
        >
          OK
        </div>
      </Button>
    </DialogActions>
  </Dialog>
);

export default LogsActionDialog;
