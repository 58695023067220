import React from "react";
import { withStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { API } from "aws-amplify";
import { realizeAction } from "../../Helpers/realize_action";
import { CircularLoading } from "../../Styles/ComponentStyles";
import ResourceTable from "../Tables/ResourceTable";
import { styles } from "../../Styles/ComponentStyles";
import Config from "../../config.js";

class AWSResourcesDashboardExpansionPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props,
      open: false,
      validation_value: "",
      userIdToken: props.userIdToken,
      aws_service_descriptions: Config.AWS_SERVICE_NAMES_DESCRIPTION,
      table_response: [],
      listResourcesInProgress: [],
      expanded_services: []
    };

    this.handleExpansionPanelChange = this.handleExpansionPanelChange.bind(
      this
    );
    this.list_resources = this.list_resources.bind(this);
  }

  componentWillReceiveProps(props) {
    this.setState({
      account_id: props.account_id
    });
  }

  list_resources(resource_service, resource_action) {
    if (this.state.listResourcesInProgress[resource_service]) {
      return;
    }
    // let tempListResourcesInProgress = this.state.listResourcesInProgress
    let tempListResourcesInProgress = {};
    tempListResourcesInProgress[resource_service] = true;
    this.setState(
      {
        listResourcesInProgress: tempListResourcesInProgress
      },
      () => {
        let temp_table_response = this.state.table_response;
        API.post("api-exsi-backend", "/realize", {
          queryStringParameters: {
            boto_service: resource_service,
            boto_method: resource_action,
            account_id: this.state.account_id
          },
          headers: {
            Authorization: this.state.userIdToken
          }
        }).then(response => {
          if (resource_service === "ecs") {
            API.post("api-exsi-backend", "/realize", {
              queryStringParameters: {
                boto_service: resource_service,
                boto_method: "list_clusters",
                account_id: this.state.account_id
              },
              headers: {
                Authorization: this.state.userIdToken
              }
            }).then(response_list_clusters => {
              let cluster_arn = response_list_clusters["clusterArns"][0];
              for (let i = 0; i < response["families"].length; i++) {
                realizeAction(
                  this.props.userIdToken,
                  this.state.account_id,
                  this.state.resource_service,
                  "describe_task_definition",
                  response["families"][i],
                  "taskDefinition",
                  false,
                  response => {
                    if (response instanceof Error) return;
                    if (
                      !("definitions" in temp_table_response[resource_service])
                    ) {
                      temp_table_response[resource_service]["definitions"] = [];
                    }
                    let temp_table = response["taskDefinition"];
                    let body = {
                      family: response["taskDefinition"]["family"],
                      cluster: cluster_arn
                    };
                    API.post("api-exsi-backend", "/realize", {
                      queryStringParameters: {
                        boto_service: "ecs",
                        boto_method: "list_tasks",
                        account_id: this.state.account_id,
                        region: "eu-west-1"
                      },
                      body: body
                    }).then(response => {
                      temp_table["tasks"] = response["taskArns"];
                      temp_table["task_number"] = response["taskArns"].length;
                      temp_table["running_task"] =
                        response["taskArns"].length === 0 ? "non" : "oui";
                      temp_table_response[resource_service]["definitions"].push(
                        temp_table
                      );
                      tempListResourcesInProgress[resource_service] = false;
                      this.setState({
                        listResourcesInProgress: tempListResourcesInProgress
                      });
                    });
                  },
                  null,
                  null,
                  null,
                  null,
                  null
                );
              }
            });
            this.setState({
              listResourcesInProgress: tempListResourcesInProgress,
              table_response: temp_table_response,
              seconds: 0
            });
            temp_table_response[resource_service] = response;
            this.setState({
              table_response: temp_table_response
            });
          } else {
            temp_table_response[resource_service] = response;
            this.setState({
              table_response: temp_table_response,
              seconds: 0,
              listResourcesInProgress: false
            });
          }
        });
      }
    );
  }

  handleExpansionPanelChange = (service, event, expanded) => {
    let temp_expanded_services = this.state.expanded_services;
    if (expanded) {
      switch (service.toLowerCase().toString()) {
        case "ec2":
          this.list_resources("ec2", "describe_instances");
          this.setState({
            resource_service: "ec2",
            resource_action: "describe_instances"
          });
          temp_expanded_services.push(service.toLowerCase().toString());
          break;
        case "rds":
          this.list_resources("rds", "describe_db_instances");
          this.setState({
            resource_service: "rds",
            resource_action: "describe_db_instances"
          });
          temp_expanded_services.push(service.toLowerCase().toString());
          break;
        case "s3":
          this.list_resources("s3", "list_buckets");
          this.setState({
            resource_service: "s3",
            resource_action: "list_buckets"
          });
          temp_expanded_services.push(service.toLowerCase().toString());
          break;
        case "lambda":
          this.list_resources("lambda", "list_functions");
          this.setState({
            resource_service: "lambda",
            resource_action: "list_functions"
          });
          temp_expanded_services.push(service.toLowerCase().toString());
          break;
        case "autoscaling":
          this.list_resources("autoscaling", "describe_auto_scaling_groups");
          this.setState({
            resource_service: "autoscaling",
            resource_action: "describe_auto_scaling_groups"
          });
          temp_expanded_services.push(service.toLowerCase().toString());
          break;
        case "sqs":
          this.list_resources("sqs", "list_queues");
          this.setState({
            resource_service: "sqs",
            resource_action: "list_queues"
          });
          temp_expanded_services.push(service.toLowerCase().toString());
          break;
        case "ecs":
          this.list_resources("ecs", "list_task_definition_families");
          this.setState({
            resource_service: "ecs",
            resource_action: "list_task_definition_families"
          });
          temp_expanded_services.push(service.toLowerCase().toString());
          break;
        default:
          break;
      }
    } else if (!expanded) {
      let index = temp_expanded_services.indexOf(
        service.toLowerCase().toString()
      );
      temp_expanded_services.splice(index, 1);
    }

    this.setState({
      expanded_services: temp_expanded_services
    });
  };

  renderResourceTable(resource_service, resource_action) {
    if (resource_service === this.state.resource_service) {
      if (this.state.listResourcesInProgress[this.state.resource_service]) {
        return (
          <div>
            <Typography>Récupération de l'état des ressources...</Typography>
            <CircularLoading> </CircularLoading>
          </div>
        );
      } else {
        return (
          <ResourceTable
            onRef={ref => (this.ResourceTable = ref)}
            account_id={this.state.account_id}
            user_name={this.props.user_name}
            userIdToken={this.state.userIdToken}
            resource_service={resource_service}
            resource_action={resource_action}
            mobile={this.state.mobile}
            table_response={this.state.table_response}
            expanded_services={this.state.expanded_services}
            current_service={this.state.resource_service}
            listResourcesInProgress={this.state.listResourcesInProgress}
            app={this.state.app}
            env={this.state.env}
          />
        );
      }
    } else if (this.state.resource_service !== undefined) {
      return (
        <ResourceTable
          onRef={ref => (this.ResourceTable = ref)}
          account_id={this.state.account_id}
          user_name={this.props.user_name}
          userIdToken={this.state.userIdToken}
          resource_service={this.state.resource_service}
          resource_action={this.state.resource_action}
          mobile={this.state.mobile}
          table_response={this.state.table_response}
          expanded_services={this.state.expanded_services}
          current_service={this.state.resource_service}
          listResourcesInProgress={this.state.listResourcesInProgress}
          app={this.state.app}
          env={this.state.env}
        />
      );
    }
  }

  renderPanel() {
    if (this.state.account_id) {
      return this.state.aws_service_descriptions.map((row, index) => {
        if (row.SERVICE_NAME.toLowerCase().toString() in Config.calls) {
          return (
            <ExpansionPanel
              key={row.SERVICE_NAME}
              onChange={this.handleExpansionPanelChange.bind(
                this,
                row.SERVICE_NAME
              )}
            >
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                {
                  <Typography className={this.state.classes.headingPanel}>
                    <strong>{row.SERVICE_NAME}</strong>{" "}
                    {" - " + row.SERVICE_DESCRIPTION.toString()}
                  </Typography>
                }
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div>
                  {row.SERVICE_NAME.toLowerCase().toString() === "ec2"
                    ? this.renderResourceTable("ec2", "describe_instances")
                    : null}
                  {row.SERVICE_NAME.toLowerCase().toString() === "rds"
                    ? this.renderResourceTable("rds", "describe_db_instances")
                    : null}
                  {row.SERVICE_NAME.toLowerCase().toString() === "s3"
                    ? this.renderResourceTable("s3", "list_buckets")
                    : null}
                  {row.SERVICE_NAME.toLowerCase().toString() === "lambda"
                    ? this.renderResourceTable("lambda", "list_functions")
                    : null}
                  {row.SERVICE_NAME.toLowerCase().toString() === "autoscaling"
                    ? this.renderResourceTable(
                        "autoscaling",
                        "describe_auto_scaling_groups"
                      )
                    : null}
                  {row.SERVICE_NAME.toLowerCase().toString() === "sqs"
                    ? this.renderResourceTable("sqs", "list_queues")
                    : null}
                  {row.SERVICE_NAME.toLowerCase().toString() === "ecs"
                    ? this.renderResourceTable(
                        "ecs",
                        "list_task_definition_families"
                      )
                    : null}
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          );
        } else {
          return null;
        }
      });
    } else {
      return (
        <div className="loading" style={{ textAlign: "center" }}>
          <CircularLoading />
        </div>
      );
    }
  }

  render() {
    return (
      <div className={this.state.classes.rootPanel}>{this.renderPanel()}</div>
    );
  }
}

export default withStyles(styles)(AWSResourcesDashboardExpansionPanel);
