import React, { useCallback, useEffect, useState } from "react";
import { getUserProfiles } from "../../Services/UserProfilesService";
import { retrieveAllPerimeters } from "../../Services/PerimetersService";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import UsersManagementRow from "./UsersManagementRow";
import { CircularLoading } from "../../Styles/ComponentStyles";
import { Button } from "@material-ui/core";
import UsersManagementCreate from "./UsersManagementCreate";
import withStyles from "@material-ui/core/styles/withStyles";

const useMediaQuery = (query) => {
  const mediaMatch = window.matchMedia(query);
  const [matches, setMatches] = useState(mediaMatch.matches);

  useEffect(() => {
    const handler = e => setMatches(e.matches);
    mediaMatch.addListener(handler);
    return () => mediaMatch.removeListener(handler);
  });
  return matches;
};

const styles = {
  container: isRowBased => ({
    display: isRowBased ? 'table-cell' : 'none'
  })
};

const UsersManagementDashboard = (classes) => {
  const [userProfiles, setUserProfiles] = useState(undefined);
  const [perimeters, setPerimeters] = useState(undefined);
  const [isCreateUserOpened, setIsCreateUserOpened] = useState(false);

  const handleCreateUserClose = () => setIsCreateUserOpened(false);

  useEffect(() => {
    const retrieveUserProfiles = async () => {
      setUserProfiles(await getUserProfiles());
    };

    retrieveUserProfiles();
  }, []);
  const isRowBased = useMediaQuery('(min-width: 500px)');

  useEffect(() => {
    const retrievePerimeters = async () => {
      setPerimeters(
        (await retrieveAllPerimeters()).map(
          perimeter => perimeter["Perimetre Name"]
        )
      );
    };

    retrievePerimeters();
  }, []);

  const userProfileReloadCallBack = useCallback(async () => {
    setIsCreateUserOpened(false);
    setUserProfiles(undefined);
    setUserProfiles(await getUserProfiles());
  }, []);

  return (
    <div>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell align="center" style={{ fontSize: "large", padding: '0'  }}>
              <b>UserName</b>
            </TableCell>
            <TableCell align="center" style={styles.container(isRowBased)}>
              <b>Mail</b>
            </TableCell>
            <TableCell align="center" style={styles.container(isRowBased)}>
              <b>FirstName</b>
            </TableCell>
            <TableCell align="center" style={styles.container(isRowBased)}>
              <b>LastName</b>
            </TableCell>
            <TableCell align="center" style={{ fontSize: "large", padding: '0'  }}>
              <b>Perimeters</b>
            </TableCell>
            <TableCell align="center" style={{ fontSize: "large", padding: '0'  }}>
              <b>Supprimer</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {userProfiles &&
            perimeters &&
            userProfiles.map((userProfile, index) => (
              <UsersManagementRow
                key={index}
                userProfile={userProfile}
                perimeters={perimeters}
                userProfileUpdateCallBack={userProfileReloadCallBack}
              />
            ))}
        </TableBody>
      </Table>
      {!(userProfiles && perimeters) && (
        <div>
          <CircularLoading />
        </div>
      )}
      <Button
        color="primary"
        variant="contained"
        onClick={() => setIsCreateUserOpened(true)}
      >
        Ajouter un utilisateur
      </Button>
      <UsersManagementCreate
        open={isCreateUserOpened}
        onClose={handleCreateUserClose}
        onValidate={userProfileReloadCallBack}
      ></UsersManagementCreate>
    </div>
  );
};

export default withStyles(withStyles)(UsersManagementDashboard);
