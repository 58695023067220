import React from "react";
import { ListItem } from "@material-ui/core";
import Button from "@material-ui/core/Button";

const HeadlineRow = ({ headlineRowInformation }) => (
  <ListItem>
    {headlineRowInformation.title}
    {headlineRowInformation.linkValue && headlineRowInformation.linkName && (
      <Button
        size="small"
        color="primary"
        onClick={() => {
          window.open(headlineRowInformation.linkValue, "_blank");
        }}
      >
        {headlineRowInformation.linkName}
      </Button>
    )}
  </ListItem>
);

export default HeadlineRow;
