import React from "react";

import { withStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Cached from "@material-ui/icons/Cached";
import Grid from "@material-ui/core/Grid";

import { withSnackbar } from "notistack";
import moment from "moment";
import PropTypes from "prop-types";

import Circle from "../Geometric/Circle";
import Tooltip from "@material-ui/core/Tooltip";
import { CircularLoading } from "../../Styles/ComponentStyles";
import { realizeAction } from "../../Helpers/realize_action";
import { styles, circleColor } from "../../Styles/ComponentStyles";
import { getAccountState } from "../../Services/AccountService";

//Set the fr language for moment
moment.locale("fr");

class AlarmDashboardTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      alarms: undefined,
      interesting_alarms: undefined,
      listAlarmInProgress: false,
      loading: false,
      current_alarm: undefined,
      form_account_id_error: false,
      seconds: 0,
      applicationState: undefined
    };
    this.listAlarms = this.listAlarms.bind(this);
    this.getAlarmsListCallback = this.getAlarmsListCallback.bind(this);
  }

  componentDidMount() {
    this.interval = setInterval(() => this.tick(), 1000);
    this.componentWillReceiveProps(this.props);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  tick() {
    this.setState(prevState => ({
      seconds: prevState.seconds + 1
    }));
  }

  getModalStyle() {
    return {
      margin: "auto"
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      ...nextProps
    });

    if (nextProps.account_id !== undefined) {
      if (
        this.state.alarms === undefined ||
        this.state.account_id !== nextProps.account_id
      ) {
        this.setState({
          loading: true
        });
        getAccountState(this.state.account_id).then(result => {
          this.setState({
            applicationState: result
          });
          if (
            this.state.applicationState &&
            this.state.applicationState === "up"
          ) {
            this.listAlarms(nextProps.account_id);
          }
        });
      }
    }
  }

  getAlarmsListCallback(response) {
    let interesting_alarms = [];
    response.forEach((item, index) => {
      if (item["StateValue"] === "ALARM") {
        interesting_alarms.push(item);
      }
    });
    this.setState({
      alarms: response,
      interesting_alarms: interesting_alarms,
      loading: false,
      listAlarmInProgress: false,
      seconds: 0
    });
  }

  listAlarms(account_id) {
    if (this.state.listAlarmInProgress || !account_id) {
      return;
    }
    this.setState(
      {
        listAlarmInProgress: true
      },
      () => {
        realizeAction(
          this.state.userIdToken,
          account_id,
          "cloudwatch",
          "describe_alarms",
          null,
          null,
          null,
          this.getAlarmsListCallback,
          "NextToken",
          "NextToken",
          null,
          [],
          "MetricAlarms"
        );
      }
    );
  }

  renderCircle(status, alarm_action, ok_action, classes) {
    let exsi_sns_arns = [
      "arn:aws:sns:eu-west-1:182726513909:snt-exsi-notifications",
      "arn:aws:sns:eu-west-1:815704857845:snt-exsi-notifications",
      "arn:aws:sns:eu-west-1:726054927466:snt-exsi-notifications"
    ];
    let account_exsi_sns_topic_exist = ["false", "false", "false"];
    let counter_exsi_arn_exsit = 0;
    exsi_sns_arns.map(sns_arn => {
      if (alarm_action.includes(sns_arn) && ok_action.includes(sns_arn)) {
        account_exsi_sns_topic_exist[counter_exsi_arn_exsit] = "true";
        counter_exsi_arn_exsit = counter_exsi_arn_exsit + 1;
      }
      return null;
    });
    if (counter_exsi_arn_exsit !== 3) {
      return (
        <Grid container spacing={8} className={classes.gridContainer}>
          <Grid item xs={3}>
            <Circle bgColor={circleColor.yellow} circleSize={20} />
          </Grid>
          <Grid item xs={6}>
            <Typography>
              (L'alarme n'est pas configurée correctement)
            </Typography>
          </Grid>
        </Grid>
      );
    }

    switch (status) {
      case "ALARM":
        return <Circle bgColor={circleColor.red} circleSize={20} />;

      case "OK":
        return <Circle bgColor={circleColor.green} circleSize={20} />;

      default:
        return <Circle bgColor={circleColor.gray} circleSize={20} />;
    }
  }

  render() {
    const { classes } = this.props;
    if (!this.state.applicationState) {
      return (
        <div className="loading" style={{ textAlign: "center" }}>
          <CircularLoading />
        </div>
      );
    } else if (this.state.applicationState === "down") {
      return (
        <div>
          <Circle bgColor={circleColor.black} circleSize={40} />
          <Typography variant="h6" id="modal-title">
            L'environnement a été éteint, vous pouvez le rallumer avec l'action
            laf-env-controller-start.
          </Typography>
        </div>
      );
    } else if (
      this.state.loading === false &&
      this.state.interesting_alarms &&
      this.state.interesting_alarms.length === 0
    ) {
      return (
        <div>
          <Circle bgColor={circleColor.green} circleSize={40} />
          <Typography variant="h6" id="modal-title">
            L'environnement fonctionne correctement
          </Typography>
        </div>
      );
    } else if (
      this.state.loading === false &&
      this.state.interesting_alarms &&
      this.state.interesting_alarms.length > 0
    ) {
      return (
        <div>
          <Circle bgColor={circleColor.red} circleSize={40} />
          <Typography variant="h6" id="modal-title">
            L'environnement présente des alarmes
          </Typography>
          <Paper className={classes.root}>
            <Button
              size="small"
              color="primary"
              onClick={() => {
                this.setState(
                  {
                    seconds: 0
                  },
                  () => {
                    this.setState({
                      loading: true
                    });
                    this.listAlarms(this.state.account_id);
                  }
                );
              }}
            >
              <Cached /> Rafraîchir ({this.state.seconds} s)
            </Button>
            <Paper className={classes.paperAlarmList}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>Statut</TableCell>
                    <TableCell>Alarme</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.interesting_alarms.map(row => (
                    <TableRow key={row.AlarmArn}>
                      <TableCell align="left">
                        {this.renderCircle(
                          row.StateValue,
                          row.AlarmActions,
                          row.OKActions,
                          classes
                        )}
                      </TableCell>
                      <TableCell align="left">
                        <Tooltip
                          title={
                            row.AlarmDescription ||
                            "Pas de description disponible."
                          }
                          classes={{ tooltip: classes.lightTooltip }}
                        >
                          <Typography>{row.AlarmName}</Typography>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          </Paper>
        </div>
      );
    } else if (this.state.loading === true) {
      return (
        <div className="loading" style={{ textAlign: "center" }}>
          <CircularLoading />
        </div>
      );
    } else {
      return "";
    }
  }
}

AlarmDashboardTable.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withSnackbar(withStyles(styles)(AlarmDashboardTable));
