import React from "react";
import DashboardExpansionPanel from "../Components/Panels/DashboardExpansionPanel";
import { withStyles } from "@material-ui/core/styles";
import { Redirect } from "react-router";

import { styles } from "../Styles/ComponentStyles";

class HighLevelDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      account_list: this.props.account_list,
      user_name: this.props.user_name,
      userIdToken: this.props.userIdToken,
      noApps: this.props.noApps,
      grid_direction: "row",
      app_clickable: true
    };
  }

  render() {
    if (!this.state.noApps) {
      return (
        <DashboardExpansionPanel
          account_list={this.state.account_list}
          user_name={this.state.user_name}
          userIdToken={this.state.userIdToken}
          grid_direction={this.state.grid_direction}
          app_clickable={this.state.app_clickable}
        />
      );
    } else {
      return <Redirect to="/alerte-apps" />;
    }
  }
}

export default withStyles(styles)(HighLevelDashboard);
