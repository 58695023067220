import React, { useState } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Button, Dialog, DialogContent } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import { updateUserProfile } from "../../Services/UserProfilesService";
import { CircularLoading } from "../../Styles/ComponentStyles";
import moment from "moment";
import { addLog } from "../../Services/LogsService";
import { getCurrentUserProfile } from "../../Services/AuthService";
import TextField from "@material-ui/core/TextField";

const UsersManagementCreate = ({ open, onClose, onValidate }) => {
  const [userNameToCreate, setUserNameToCreate] = useState("");
  const [userMailToCreate, setUserMailToCreate] = useState("");
  const [isCreateInProgress, setIsCreateInProgress] = useState(false);

  const handleClose = () => {
    setUserNameToCreate("");
    setIsCreateInProgress(false);
    onClose();
  };

  const handleValidate = async () => {
    setIsCreateInProgress(true);
    await updateUserProfile("faro_" + userNameToCreate.toUpperCase(), {
      perimeters: [],
      userEmail: userMailToCreate
    });
    const currentUser = await getCurrentUserProfile();
    logCreate(currentUser);
    onValidate();
  };

  const logCreate = currentUser => {
    const currentTime = moment().unix();
    const actionDescription =
      "Création d'un nouvel utilisateur: faro_" +
      userNameToCreate.toUpperCase() +
      "avec le mail: " +
      userMailToCreate;
    addLog(currentUser.UserName, currentTime, actionDescription);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle
        style={{
          backgroundColor: "#3f51b5",
          textAlign: "center"
        }}
      >
        <div style={{ color: "white" }}>Ajouter un nouvel utilisateur</div>
      </DialogTitle>
      {!isCreateInProgress ? (
        <DialogContent>
          <TextField
            id={"add-user-id"}
            label={"Identifiant FARO de l'utilisateur à créer. Exemple: 0906RC"}
            variant="outlined"
            defaultValue={""}
            error={userNameToCreate === ""}
            onChange={event => setUserNameToCreate(event.target.value)}
            fullWidth
            margin="dense"
          />
          <TextField
            id={"add-user-mail"}
            label={"Mail de l'utilisateur à créer."}
            variant="outlined"
            defaultValue={""}
            error={userMailToCreate === ""}
            onChange={event => setUserMailToCreate(event.target.value)}
            fullWidth
            margin="dense"
          />
        </DialogContent>
      ) : (
        <CircularLoading />
      )}
      <DialogActions>
        <Button
          onClick={handleClose}
          variant={"contained"}
          disabled={isCreateInProgress}
        >
          <div
            style={{
              color: "white"
            }}
          >
            Annuler
          </div>
        </Button>
        <Button
          onClick={handleValidate}
          variant={"contained"}
          style={{
            backgroundColor: "#32CD32"
          }}
          disabled={
            isCreateInProgress ||
            userNameToCreate === "" ||
            userMailToCreate === ""
          }
        >
          <div
            style={{
              color: "white"
            }}
          >
            Valider
          </div>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UsersManagementCreate;
