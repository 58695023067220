import { withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import { circularLoading } from "@yami-beta/react-circular-loading";

export const circleColor = {
  green: "#32CD32",
  blue: "#3f51b5",
  amber: "#ff8000",
  red: "#ff0000",
  gray: "#f0f0f0",
  yellow: "#ffff00",
  black: "#000000"
};

export const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

export const CircularLoading = circularLoading({
  // Distance of dot from center `em`
  distance: 1,
  // Dot's size `em`
  dotSize: 0.5,
  // Dot's color (base color)
  dotColor0: `rgba(0, 169, 132, 0.1)`,
  // Dot's color (semi highlight color)
  dotColor1: `rgba(0, 169, 132, 0.2)`,
  // Dot's color (highlight color)
  dotColor2: `rgba(0, 169, 132, 1.0)`,
  // A number of dots
  num: 8,
  // Animation speed `ms`
  speed: 1000
});

export const CircularLoadingButton = circularLoading({
  // Distance of dot from center `em`
  distance: 0.6,
  // Dot's size `em`
  dotSize: 0.4,
  // Dot's color (base color)
  dotColor0: `rgba(0, 169, 132, 0.1)`,
  // Dot's color (semi highlight color)
  dotColor1: `rgba(0, 169, 132, 0.2)`,
  // Dot's color (highlight color)
  dotColor2: `rgba(0, 169, 132, 1.0)`,
  // A number of dots
  num: 8,
  // Animation speed `ms`
  speed: 1000,

  size: "large"
});

export const styles = theme => ({
  lightTooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11
  },

  paperModalSurveillance: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
    width: "80%"
  },

  // For AppCards.js & AWSResourcesAppCards.js
  card: {
    maxWidth: 345
  },
  media: {
    height: 140
  },
  imgCard: {
    maxHeight: 140,
    maintainAspectRatio: true
  },

  // For EsRedirect.js
  rootEsRedirect: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2
  },

  // For NoApps.js
  rootNoApps: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2
  },

  // For DefaultWarning.js
  rootDefaultWarning: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2
  },

  // For Progress.js
  progressInstanceProgress: {
    margin: theme.spacing.unit * 2
  },

  // For AppInfoMessage.js & EnvInfoMessage.js
  rootInfoMessage: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2
  },

  // For AWSResourcesDashboardExpansionPanel.js & DashboardExpansionPanel.js
  rootPanel: {
    width: "100%",
    overflow: "scroll"
  },
  headingPanel: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },

  // For PrimarySearchAppBar.js
  rootSearchBar: {
    width: "100%"
  },
  growSearchBar: {
    flexGrow: 1
  },
  menuButtonSearchBar: {
    marginLeft: -12,
    marginRight: 20
  },
  titleSearchBar: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block"
    }
  },
  sectionDesktopSearchBar: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex"
    }
  },
  sectionMobileSearchBar: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },

  // For LowLevelDashboard Tabs
  dashboardAppBar: {
    width: "100%",
    backgroundColor: "#00a984"
  },

  // For ApplicationSelector.js
  formControlAppSelector: {
    padding: theme.spacing.unit * 3,
    minWidth: 120,
    width: 30,
    margin: "auto",
    textAlign: "center"
  },

  // For ChannelMethodSelector.js
  formChannelMethodSelector: {
    padding: theme.spacing.unit * 3,
    minWidth: 120,
    width: 30,
    margin: "auto",
    textAlign: "center"
  },

  paperAppSelect: {
    marginTop: theme.spacing.unit * 3,
    width: "100%",
    overflowX: "auto"
  },

  paperAlarmList: {
    marginTop: theme.spacing.unit * 3,
    width: "100%",
    height: "400px",
    overflow: "auto"
  },

  gridContainer: {
    padding: theme.spacing.unit
  },

  headlines: {
    padding: theme.spacing.unit
  },

  envSelector: {
    padding: theme.spacing.unit * 3,
    minWidth: 120
  },

  // For AccountSelector.js
  formControlAccountSelector: {
    margin: theme.spacing.unit,
    minWidth: 120
  },

  // For Table.js
  paperTable: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto"
  },
  tableTable: {
    minWidth: 700
  },
  rowTable: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    }
  },

  pre: {
    display: "block",
    padding: "10px 30px",
    margin: "0",
    overflow: "scroll"
  },

  // For TablePaginateLogActions.js
  rootPaginate: {
    width: "100%",
    marginTop: theme.spacing.unit * 3
  },
  tablePaginate: {
    minWidth: 500
  },
  tableWrapperPaginate: {
    overflowX: "auto"
  },

  dutyButton: {
    display: "flex",
    justify: "center"
  }
});

export const actionsStyles = theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing.unit * 2.5
  }
});
