import React from "react";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";
import {
  CircularLoading,
  CustomTableCell,
  styles
} from "../Styles/ComponentStyles";

import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import TextField from "@material-ui/core/TextField";
import { Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withSnackbar } from "notistack";
import { withStyles } from "@material-ui/core/styles";

import ViewList from "@material-ui/icons/ViewList";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import moment from "moment";
import {
  addDuty,
  deleteDuty,
  enrichDutyList,
  getDutyList
} from "../Services/DutyService";
import {
  getUserProfiles,
  retrieveUsersWithPerimeter
} from "../Services/UserProfilesService";
import { retrieveAllPerimeters } from "../Services/PerimetersService";
import { retrieveChannelsWithParameters } from "../Services/ChannelsService";
import { retrieveAdmins } from "../Services/AdminService";
import { addLog } from "../Services/LogsService";

class Astreintes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      listDutyInProgress: false,
      modalAddDutyOpen: false,
      modalUpdateDutyOpen: false,
      dutyList: [],
      perimeters: [],
      selectedPerimeter: "",
      selectedUser: "",
      user_list: [],
      userProfiles: [],
      form_error: true,
      deleteDialogOpen: false,
      duty_for_deletion: "",
      perimeterList: [],
      selectedDuty: "HO",
      channelList: [],
      isChannelListFetched: false,
      isDutyListFetched: false,
      isAdmin: undefined,
      ...props
    };

    this.handleAddDutyClose = this.handleAddDutyClose.bind(this);
    this.handlePerimeterChange = this.handlePerimeterChange.bind(this);
    this.handleUserChange = this.handleUserChange.bind(this);
    this.handleUpdateDutyClose = this.handleUpdateDutyClose.bind(this);
    this.handleCloseDeleteDialog = this.handleCloseDeleteDialog.bind(this);
  }

  componentDidMount() {
    this.listDuty();
  }

  getAllPerimeters() {
    retrieveAllPerimeters().then(response => {
      this.setState({
        perimeters: response.map(perimeter => ({
          PerimeterName: perimeter["Perimetre Name"],
          ApplicationName: perimeter["Application Name"]
        }))
      });
    });
  }

  searchUserWithPerimeter(perimeter) {
    this.setState({
      user_list: retrieveUsersWithPerimeter(this.state.userProfiles, perimeter)
    });
  }

  listDuty() {
    Promise.all([
      retrieveAdmins("duty"),
      getDutyList(),
      getUserProfiles(),
      this.getAllPerimeters()
    ]).then(responses => {
      const isAdmin = responses[0].indexOf(this.state.user_name) > -1;
      const responseDutyList = responses[1];
      const responseUserProfiles = responses[2];
      const enrichedDutyList = enrichDutyList(
        responseDutyList,
        responseUserProfiles
      );
      const currentUserPerimeters = responseUserProfiles.find(
        profile => profile.UserName === this.state.user_name
      ).Perimeters;
      this.setState({
        isAdmin,
        dutyList: isAdmin
          ? enrichedDutyList
          : enrichedDutyList.filter(
              duty => currentUserPerimeters.indexOf(duty.PerimeterName) > -1
            ),
        isDutyListFetched: true,
        userProfiles: responseUserProfiles
      });
    });
  }

  handleAddDutyClickOpen() {
    this.setState({
      modalAddDutyOpen: true
    });
  }

  handleAddDutyClose() {
    this.setState({
      modalAddDutyOpen: false,
      selectedUser: ""
    });
  }

  handleAddDutyClick() {
    addDuty(
      this.state.selectedUser,
      this.state.selectedPerimeter,
      this.state.selectedDuty
    ).then(() => {
      const action_description =
        "Ajout d'une astreinte " +
        this.state.selectedDuty +
        ": " +
        " pour le périmètre " +
        this.state.selectedPerimeter +
        " avec l'agent " +
        this.state.selectedUser;
      const current_time = moment().unix();
      addLog(this.state.user_name, current_time, action_description);

      this.listDuty();
      this.handleAddDutyClose();
      this.props.enqueueSnackbar(action_description, {
        variant: "success",
        autoHideDuration: 10000
      });
    });
  }

  handleUpdateDutyClick() {
    addDuty(
      this.state.selectedUser,
      this.state.selectedPerimeter,
      this.state.selectedDuty
    ).then(() => {
      const action_description =
        "Modification d'une astreinte " +
        this.state.selectedDuty +
        ": " +
        " pour le périmètre " +
        this.state.selectedPerimeter +
        " avec l'agent " +
        this.state.selectedUser;
      const current_time = moment().unix();
      addLog(this.state.user_name, current_time, action_description);

      this.listDuty();
      this.handleAddDutyClose();
      this.props.enqueueSnackbar(action_description, {
        variant: "success",
        autoHideDuration: 10000
      });
      this.setState({
        modalUpdateDutyOpen: false,
        form_error: true,
        channelList: [],
        selectedUser: "",
        isChannelListFetched: false
      });
    });
  }

  handleFormError() {
    if (this.state.selectedPerimeter && this.state.selectedUser) {
      this.setState({
        form_error: false
      });
    } else {
      this.setState({
        form_error: true
      });
    }
  }

  handlePerimeterChange = event => {
    this.searchUserWithPerimeter(event.target.value);
    this.setState(
      {
        selectedPerimeter: event.target.value
      },
      () => {
        this.handleFormError();
      }
    );
  };

  handleUserChange = event => {
    this.setState(
      {
        selectedUser: event.target.value
      },
      () => {
        this.handleFormError();
      }
    );
  };

  handleDutyChange = event => {
    this.setState({
      selectedDuty: event.target.value
    });
  };

  renderPerimeterOptions() {
    if (Object.keys(this.state.perimeters).length !== 0) {
      let optionList = this.state.perimeters.map(select => {
        return (
          <option key={select["PerimeterName"]} value={select["PerimeterName"]}>
            {select["PerimeterName"]}
          </option>
        );
      });
      optionList.unshift(
        <option
          key="Sélectionner un périmètre"
          value="Sélectionner un périmètre"
        >
          Sélectionner un périmètre
        </option>
      );
      return [optionList];
    }
  }

  renderUserOptions() {
    if (this.state.user_list.length !== 0) {
      let optionList = this.state.user_list.map(select => {
        return (
          <option key={select.UserName} value={select.UserName}>
            {select.UserName} - {select.FirstName} {select.LastName}
          </option>
        );
      });
      optionList.unshift(
        <option key="Sélectionner un agent" value="Sélectionner un agent">
          Sélectionner un agent
        </option>
      );
      return [optionList];
    }
  }

  renderAddDuty() {
    return (
      <div>
        <Grid container justify="space-around">
          <Button
            onClick={() => this.handleAddDutyClickOpen()}
            color="primary"
            key="open_add_user"
          >
            Ajouter une astreinte
          </Button>
        </Grid>

        <Dialog
          open={this.state.modalAddDutyOpen}
          onClose={this.handleAddDutyClose}
          aria-labelledby="form-user-dialog-title"
        >
          <DialogTitle id="form-user-dialog-title">
            Ajouter une astreinte
          </DialogTitle>
          <DialogContent>
            <section key="perimeters">
              <FormControl key="perimeters">
                <Select
                  native
                  key="perimeters"
                  value={this.state.selectedPerimeter}
                  id="perimeters"
                  onChange={this.handlePerimeterChange}
                >
                  {this.renderPerimeterOptions()}
                </Select>
              </FormControl>
            </section>
            <section key="user">
              <FormControl key="user">
                <Select
                  native
                  key="user"
                  value={this.state.selectedUser}
                  id="user"
                  onChange={this.handleUserChange}
                >
                  {this.renderUserOptions()}
                </Select>
              </FormControl>
            </section>
            <section key="duty">
              <FormControl key="duty">
                <Select
                  native
                  key="duty"
                  value={this.state.selectedDuty}
                  id="duty"
                  onChange={this.handleDutyChange}
                >
                  <option key="HO" value="HO">
                    HO
                  </option>
                  <option key="HNO" value="HNO">
                    HNO
                  </option>
                </Select>
              </FormControl>
            </section>
            <Grid container justify="space-around">
              <Button
                onClick={() => this.handleAddDutyClick()}
                color="primary"
                disabled={this.state.form_error}
                key="add_duty"
              >
                Ajouter cette astreinte
              </Button>
            </Grid>
          </DialogContent>
        </Dialog>
      </div>
    );
  }

  renderUpdateDuty() {
    return (
      <div>
        <Dialog
          open={this.state.modalUpdateDutyOpen}
          onClose={this.handleUpdateDutyClose}
          aria-labelledby="form-user-dialog-title"
        >
          <DialogTitle id="form-user-dialog-title">
            Modifier une astreinte
          </DialogTitle>
          {this.state.isChannelListFetched ? (
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="form-perimeter"
                label="perimeter"
                type="text"
                value={this.state.selectedPerimeter}
                disabled
                fullWidth
              />

              <section key="user">
                <FormControl key="user">
                  <Select
                    native
                    key="user"
                    value={this.state.selectedUser}
                    id="user"
                    onChange={this.handleUserChange}
                  >
                    {this.renderUserOptions()}
                  </Select>
                </FormControl>
              </section>

              <section key="duty">
                <FormControl key="duty">
                  <Select
                    native
                    key="duty"
                    value={this.state.selectedDuty}
                    id="duty"
                    onChange={this}
                    disabled
                  >
                    <option key="HO" value="HO">
                      HO
                    </option>
                    <option key="HNO" value="HNO">
                      HNO
                    </option>
                  </Select>
                </FormControl>
              </section>
              {!this.state.form_error && this.state.channelList.length > 0 && (
                <div>Les canaux suivants vont être modifiés:</div>
              )}
              {!this.state.form_error && this.state.channelList.length > 0 && (
                <table>
                  <thead>
                    <tr>
                      <th>Compte AWS</th>
                      <th>Alarme</th>
                      <th>Canal</th>
                      <th>Utilisateur</th>
                      <th>Astreinte</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.channelList.map(channel => (
                      <tr>
                        <td>{channel.AccountID}</td>
                        <td>{channel.AlarmName}</td>
                        <td>{channel.Channel}</td>
                        <td>
                          {channel.UserID} -> {this.state.selectedUser}
                        </td>
                        <td>{this.state.selectedDuty}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
              <Grid container justify="space-around">
                <Button
                  onClick={() => this.handleUpdateDutyClick()}
                  color="primary"
                  disabled={this.state.form_error}
                  key="update_user"
                >
                  Modifier cette astreinte
                </Button>
              </Grid>
            </DialogContent>
          ) : (
            <CircularLoading />
          )}
        </Dialog>
      </div>
    );
  }

  handleCloseDeleteDialog() {
    this.setState({
      deleteDialogOpen: false,
      duty_for_deletion: "",
      perimeter_for_deletion: ""
    });
  }

  handleDeleteDuty() {
    deleteDuty(
      this.state.perimeter_for_deletion,
      this.state.duty_for_deletion
    ).then(() => {
      const action_description =
        "Astreinte supprimée : " +
        this.state.duty_for_deletion +
        " " +
        this.state.perimeter_for_deletion;
      this.listDuty();
      const current_time = moment().unix();
      addLog(this.state.user_name, current_time, action_description);
      this.handleCloseDeleteDialog();
      this.props.enqueueSnackbar(action_description, {
        variant: "success",
        autoHideDuration: 10000
      });
    });
  }

  renderDeleteDialog() {
    if (this.state.deleteDialogOpen) {
      return (
        <Dialog
          open={this.state.deleteDialogOpen}
          onClose={this.handleCloseDeleteDialog}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            Suppression astreinte: {this.state.perimeter_for_deletion}{" "}
            {this.state.duty_for_deletion}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              ATTENTION - Vous êtes sur le point de supprimer une astreinte
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseDeleteDialog} color="primary">
              Annuler
            </Button>
            <Button
              onClick={() => {
                this.handleDeleteDuty();
              }}
              color="primary"
            >
              Valider
            </Button>
          </DialogActions>
        </Dialog>
      );
    }
  }

  handleDutyUpdateClickOpen(perimeter, duty) {
    this.setState(
      {
        modalUpdateDutyOpen: true,
        selectedPerimeter: perimeter,
        selectedDuty: duty
      },
      () =>
        retrieveChannelsWithParameters({
          perimeterName: this.state.selectedPerimeter,
          duty: this.state.selectedDuty,
          channel: "phone"
        })
          .then(response =>
            this.setState({
              channelList: response,
              isChannelListFetched: true
            })
          )
          .then(() => this.searchUserWithPerimeter(perimeter))
    );
  }

  handleUpdateDutyClose() {
    this.setState({
      modalUpdateDutyOpen: false,
      channelList: [],
      selectedUser: "",
      form_error: true,
      isChannelListFetched: false
    });
  }

  renderDutyRow(row) {
    let element = [];
    element.push(
      <CustomTableCell key={row.PerimeterName}>
        {row.PerimeterName}
      </CustomTableCell>
    );
    element.push(
      <CustomTableCell key={row.UserProfile.UserName}>
        {row.UserProfile.UserName} - {row.UserProfile.FirstName}{" "}
        {row.UserProfile.LastName}
      </CustomTableCell>
    );
    element.push(<CustomTableCell key={row.Duty}>{row.Duty}</CustomTableCell>);
    element.push(
      <CustomTableCell key="app-list-icon">
        <Button
          onClick={() =>
            this.handleDutyUpdateClickOpen(row.PerimeterName, row.Duty)
          }
          color="primary"
          key="duty-update"
        >
          <ViewList className={this.state.classes.icon} />
        </Button>
      </CustomTableCell>
    );
    element.push(
      <CustomTableCell key="delete-icon">
        <Button
          onClick={() =>
            this.setState({
              deleteDialogOpen: true,
              perimeter_for_deletion: row.PerimeterName,
              duty_for_deletion: row.Duty
            })
          }
          color="primary"
          key="delete-user"
          disabled={row.PerimeterName === "CCoE" || !this.state.isAdmin}
        >
          <DeleteOutlinedIcon className={this.state.classes.icon} />
        </Button>
      </CustomTableCell>
    );
    return element;
  }

  renderDutyRows() {
    return this.state.dutyList.map((duty, index) => (
      <TableRow key={index}>{this.renderDutyRow(duty)}</TableRow>
    ));
  }

  renderDutyHeader() {
    let element = [];
    let headers = [
      "Perimètre",
      "Agent",
      "Type astreinte",
      "Modifier agent",
      "Supprimer astreinte"
    ];
    for (let key in headers) {
      element.push(<TableCell key={key}>{headers[key]}</TableCell>);
    }
    return element;
  }

  render() {
    return !this.state.isDutyListFetched ? (
      <CircularLoading> </CircularLoading>
    ) : (
      <div>
        <Paper className="paperTable">
          <Table className="tableTable">
            <TableHead>
              <TableRow>{this.renderDutyHeader()}</TableRow>
            </TableHead>
            <TableBody>{this.renderDutyRows()}</TableBody>
          </Table>
          <div>
            {this.state.isAdmin && this.renderAddDuty()}
            {this.renderUpdateDuty()}
            {this.renderDeleteDialog()}
          </div>
        </Paper>
      </div>
    );
  }
}

export default withSnackbar(withStyles(styles)(Astreintes));
