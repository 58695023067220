import React from "react";
import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import { styles } from "../../Styles/ComponentStyles";

class ChannelMethodSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      age: "",
      open: false
    };
  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
    this.props.onApplicationChange(event.target.value, this.props);
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  Capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  render() {
    const { classes } = this.props;
    let methods = ["phone", "email", "sms"];
    return (
      <form autoComplete="off">
        <FormControl className={classes.formControlChannelMethodSelector}>
          <Select
            native
            open={this.state.open}
            onClose={this.handleClose}
            onOpen={this.handleOpen}
            value={this.props.selectedValue}
            onChange={this.handleChange}
            inputProps={{
              name: "age",
              id: "channel-method-selector-open-select",
              value: this.props.selectedValue
            }}
          >
            {methods.map(m => (
              <option value={m} key={m}>
                {this.Capitalize(m)}
              </option>
            ))}
          </Select>
        </FormControl>
      </form>
    );
  }
}

export default withStyles(styles)(ChannelMethodSelector);
