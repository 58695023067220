import React, { memo, useState } from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { Button } from "@material-ui/core";
import ViewList from "@material-ui/icons/ViewList";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import PerimetersManagementDelete from "./PerimetersManagementDelete";
import PerimetersManagementUpdate from "./PerimetersManagementUpdate";

const PerimetersManagementRow = memo(
  ({ perimeter, applications, perimetersReloadCallBack }) => {
    const [isPerimeterUpdateOpened, setIsPerimeterUpdateOpened] = useState(
      false
    );
    const [isPerimeterDeleteOpened, setIsPerimeterDeleteOpened] = useState(
      false
    );

    const closePerimeterUpdate = () => setIsPerimeterUpdateOpened(false);
    const closePerimeterDelete = () => setIsPerimeterDeleteOpened(false);

    const validatePerimeterUpdate = () => {
      setIsPerimeterUpdateOpened(false);
      perimetersReloadCallBack();
    };

    return (
      <TableRow key={perimeter["Perimetre Name"]}>
        <TableCell align="center" style={{ fontSize: "large" }}>
          {perimeter["Perimetre Name"]}
        </TableCell>
        <TableCell align="center" style={{ fontSize: "large" }}>
          {JSON.parse(perimeter["Application Name"]).toString()}
        </TableCell>
        <TableCell align="center" style={{ fontSize: "large" }}>
          {perimeter.DutyPhoneNumber}
        </TableCell>
        <TableCell align="center" style={{ fontSize: "large" }}>
          {perimeter.Email}
        </TableCell>
        <TableCell align="center" style={{ fontSize: "large" }}>
          <Button onClick={() => setIsPerimeterUpdateOpened(true)}>
            <ViewList color="primary" />
          </Button>
        </TableCell>
        <TableCell align="center" style={{ fontSize: "large" }}>
          <Button onClick={() => setIsPerimeterDeleteOpened(true)}>
            <DeleteForeverOutlinedIcon color="primary" />
          </Button>
          <PerimetersManagementDelete
            open={isPerimeterDeleteOpened}
            onClose={closePerimeterDelete}
            onValidate={validatePerimeterUpdate}
            perimeterName={perimeter["Perimetre Name"]}
          />
          <PerimetersManagementUpdate
            open={isPerimeterUpdateOpened}
            onClose={closePerimeterUpdate}
            onValidate={validatePerimeterUpdate}
            perimeter={perimeter}
            applications={applications}
          />
        </TableCell>
      </TableRow>
    );
  }
);

export default PerimetersManagementRow;
