import API from "@aws-amplify/api/lib";

export const retrieveChannelsWithParameters = parameters =>
  API.get("api-exsi-backend-v2", "/channels", {
    queryStringParameters: {
      ...parameters
    }
  });

export const addChannel = (
  accountId,
  alarmName,
  channel,
  user,
  timeParameter
) =>
  API.post("api-exsi-backend-v2", "/channels", {
    body: {
      accountId,
      alarmName,
      channel,
      user,
      ...timeParameter
    }
  });

export const deleteChannel = (accountId, UUID) =>
  API.del("api-exsi-backend-v2", "/channels", {
    body: {
      accountId,
      UUID
    }
  });
