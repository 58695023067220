import React, { useCallback, useEffect, useState } from "react";
import List from "@material-ui/core/List";
import HeadlineRow from "./HeadlineRow";
import { CircularLoading } from "../../Styles/ComponentStyles";
import { Button } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import HeadlineUpdate from "./HeadlineUpdate";
import { getApplicationHeadline } from "../../Services/ApplicationService";

const HeadlineGeneral = ({ selectedApplication }) => {
  const [headlineInformation, setHeadlineInformation] = useState(undefined);
  const [isHeadlineUpdateOpened, setIsHeadlineUpdateOpened] = useState(false);

  useEffect(() => {
    const retrieveAccountHeadline = async () => {
      setHeadlineInformation(await getApplicationHeadline(selectedApplication));
    };

    retrieveAccountHeadline();
  }, [selectedApplication]);

  const closeHeadlineUpdate = () => setIsHeadlineUpdateOpened(false);
  const validateHeadlineUpdate = useCallback(async () => {
    setIsHeadlineUpdateOpened(false);
    setHeadlineInformation(undefined);
    setHeadlineInformation(await getApplicationHeadline(selectedApplication));
  }, [selectedApplication]);

  return headlineInformation ? (
    <div
      style={{
        maxWidth: "40%",
        minWidth: "25%",
        border: "1px solid green",
        overflow: "hidden"
      }}
    >
      <List>
        {headlineInformation.length > 0 &&
          headlineInformation.map((row, index) => (
            <HeadlineRow key={index} headlineRowInformation={row} />
          ))}
      </List>
      {headlineInformation.length === 0 && (
        <i>
          Personnaliser les informations sur votre application en cliquant sur
          le stylo
        </i>
      )}
      <div style={{ float: "right" }}>
        <Button
          size="small"
          color="primary"
          onClick={() => setIsHeadlineUpdateOpened(true)}
        >
          <CreateIcon />
        </Button>
      </div>
      <HeadlineUpdate
        open={isHeadlineUpdateOpened}
        onClose={closeHeadlineUpdate}
        onValidate={validateHeadlineUpdate}
        selectedApplication={selectedApplication}
        headlineInformation={headlineInformation}
      />
    </div>
  ) : (
    <CircularLoading />
  );
};

export default HeadlineGeneral;
