import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MenuIcon from "@material-ui/icons/Menu";
import moment from "moment";
import "moment/locale/fr";

import history from "../../history";
import { retrieveAdmins } from "../../Services/AdminService";

//Set the fr language for moment
moment.locale("fr");

class SimpleMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user_admins: [],
      perimeters_admins: []
    };
    this.setUserAdminList();
    this.setPerimetersAdminList();
  }

  state = {
    anchorEl: null
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  componentWillReceiveProps(props) {
    this.setState({
      user_name: this.props.user_name
    });
  }

  setUserAdminList() {
    retrieveAdmins("user").then(response => {
      this.setState({
        user_admins: response
      });
    });
  }

  setPerimetersAdminList() {
    retrieveAdmins("perimeters").then(response => {
      this.setState({
        perimeters_admins: response
      });
    });
  }

  render() {
    const { anchorEl } = this.state;
    return (
      <div>
        <MenuIcon
          aria-owns={anchorEl ? "simple-menu" : null}
          aria-haspopup="true"
          onClick={this.handleClick}
        />
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          <div>
            <MenuItem
              onClick={() => {
                this.handleClose();
                history.push({
                  pathname: "/mes-applications"
                });
                this.props.handleApplicationChange("", this.props);
              }}
            >
              Mes applications
            </MenuItem>

            <MenuItem
              onClick={() => {
                this.handleClose();
                history.push({
                  pathname: "/logs"
                });
                this.props.handleApplicationChange("", this.props);
              }}
            >
              Consignation des actions réalisées
            </MenuItem>

            <MenuItem
              onClick={() => {
                this.handleClose();
                history.push({
                  pathname: "/facturation"
                });
                this.props.handleApplicationChange("", this.props);
              }}
            >
              Facturation
            </MenuItem>

            {this.state.perimeters_admins.indexOf(this.state.user_name) > -1 ? (
              <MenuItem
                onClick={() => {
                  this.handleClose();
                  history.push({
                    pathname: "/gestion-perimetres"
                  });
                  this.props.handleApplicationChange("", this.props);
                }}
              >
                Gestion des perimetres
              </MenuItem>
            ) : (
              false
            )}

            <MenuItem
              onClick={() => {
                this.handleClose();
                history.push({
                  pathname: "/astreintes"
                });
                this.props.handleApplicationChange("", this.props);
              }}
            >
              Gestion des astreintes
            </MenuItem>

            {this.state.user_admins.indexOf(this.state.user_name) > -1 ? (
              <MenuItem
                onClick={() => {
                  this.handleClose();
                  history.push({
                    pathname: "/gestion-utilisateurs"
                  });
                  this.props.handleApplicationChange("", this.props);
                }}
              >
                Gestion des utilisateurs
              </MenuItem>
            ) : (
              false
            )}

            {this.state.user_admins.indexOf(this.state.user_name) > -1 ? (
              <MenuItem
                onClick={() => {
                  this.handleClose();
                  history.push({
                    pathname: "/gestion-hunt-group"
                  });
                  this.props.handleApplicationChange("", this.props);
                }}
              >
                Gestion des hunt groups
              </MenuItem>
            ) : (
              false
            )}
          </div>
        </Menu>
      </div>
    );
  }
}

export default SimpleMenu;
