import React from "react";
import { Redirect } from "react-router";
import { withSnackbar } from "notistack";

import AWSResourcesDashboardExpansionPanel from "../Components/Panels/AWSResourcesDashboardExpansionPanel";
import AlarmDashboardTable from "../Components/Tables/AlarmDashboardTable";
import SimpleAlarmDashboardTable from "../Components/Tables/SimpleAlarmDashboardTable";
import AlarmChannelsTable from "../Components/Tables/AlarmChannelsTable";
import AddChannelDialog from "../Components/Dialogs/AddChannelDialog";
import LambdaActionsGrid from "../Components/Grids/LambdaActionsGrid";
import { CircularLoading, styles } from "../Styles/ComponentStyles";
import history from "../history";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Cached from "@material-ui/icons/Cached";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";

import moment from "moment";
import "moment/locale/fr";

import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import {
  deleteChannel,
  retrieveChannelsWithParameters
} from "../Services/ChannelsService";
import { addLog } from "../Services/LogsService";
import HeadlineGeneral from "../Components/Headline/HeadlineGeneral";

const queryString = require("query-string");

//Set the fr language for moment
moment.locale("fr");

class LowLevelDashboard extends React.Component {
  constructor(props) {
    super(props);

    let parsed = queryString.parse(props.location.search);
    let app = parsed.app;
    let env = parsed.env;
    if (app) {
      props.handleApplicationChange(app, this.props);
    }
    if (env) {
      props.handleEnvironmentChange(env, this.props);
    }

    this.state = {
      ...props,
      app: app,
      env: env,
      account: null,
      noApps: this.props.noApps,
      tab_index: 1,
      getActualSurveillanceInProgress: false,
      modalAddChannelOpen: false,
      seconds: 0
    };

    this.getActualSurveillance = this.getActualSurveillance.bind(this);
    this.handleAddChannelClose = this.handleAddChannelClose.bind(this);
    this.delete_channel_callback = this.delete_channel_callback.bind(this);
  }

  componentDidMount() {
    this.interval = setInterval(() => this.tick(), 1000);
    this.componentWillReceiveProps(this.props);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  tick() {
    this.setState(prevState => ({
      seconds: prevState.seconds + 1
    }));
  }

  componentWillReceiveProps(props) {
    let temp_account = null;
    if (
      props.selected_application !== "None" &&
      props.selected_application !== null
    ) {
      if (props.environment != null) {
        temp_account = props.selected_application + "-" + props.environment;
        if (temp_account in props.account_list) {
          this.setState({
            app: props.selected_application
          });
        }
      }
    }
    this.setState({
      account: props.account_list[temp_account]
    });
    this.getActualSurveillance(props.account_list[temp_account]);
  }

  //To update state in response to a prop change. It is invoked just before rendering when new props or state are being received.
  UNSAFE_componentWillReceiveProps() {
    this.setState({
      aws_account_resources: []
    });
  }

  handleAddChannelClickOpen() {
    this.setState({
      modalAddChannelOpen: true
    });
  }

  handleAddChannelClose() {
    this.setState({
      modalAddChannelOpen: false
    });
    this.componentWillReceiveProps(this.props);
  }

  getActualSurveillance(accountId) {
    if (this.state.getActualSurveillanceInProgress || !accountId) {
      return;
    }
    this.setState(
      {
        getActualSurveillanceInProgress: true
      },
      () => {
        retrieveChannelsWithParameters({ accountId }).then(response => {
          this.setState({
            surveillance: response,
            getActualSurveillanceInProgress: false
          });
        });
      }
    );
  }

  delete_channel_callback(account_id, alarm_name, uuid) {
    deleteChannel(account_id, uuid).then(() => {
      let action_description =
        "Suppression d'un channel sur le compte : " +
        account_id +
        " avec le nom " +
        alarm_name;
      let current_time = moment().format("dddd Do MMMM YYYY H: mm: ss");
      addLog(this.props.user_name, current_time, action_description);
      this.getActualSurveillance(this.state.account_id);
      this.componentWillReceiveProps(this.props);
      this.props.enqueueSnackbar(action_description, {
        variant: "success",
        autoHideDuration: 5000
      });
    });
  }

  handleTabChange = (event, value) => {
    if (value === 0) {
      this.props.handleApplicationChange("", this.props);
      history.push({
        pathname: "/mes-applications"
      });
    }

    this.setState({
      tab_index: value
    });
  };

  renderResources() {
    return (
      <div style={{ height: "80vh" }}>
        <AWSResourcesDashboardExpansionPanel
          account_list={this.props.account_list}
          account_id={this.state.account}
          user_name={this.props.user_name}
          userIdToken={this.state.userIdToken}
          mobile={this.state.mobile}
          env={this.state.env}
          app={this.state.app}
        />
      </div>
    );
  }

  renderActions() {
    return (
      <div style={{ height: "80vh" }}>
        <LambdaActionsGrid
          account_id={this.state.account}
          userIdToken={this.state.userIdToken}
          app={this.state.app}
          env={this.state.env}
          userName={this.state.user_name}
        />
      </div>
    );
  }

  renderHeadline = () => (
    <HeadlineGeneral selectedApplication={this.state.app} />
  );

  renderSimpleAlarmDashboard() {
    return (
      <SimpleAlarmDashboardTable
        account_id={this.state.account}
        user_name={this.props.user_name}
        userIdToken={this.state.userIdToken}
      />
    );
  }

  renderAlarmDashboard() {
    return (
      <AlarmDashboardTable
        account_id={this.state.account}
        account_list={this.props.account_list}
        user_name={this.props.user_name}
        userIdToken={this.state.userIdToken}
        app={this.state.app}
        env={this.state.env}
      />
    );
  }

  renderChannelsTable() {
    if (
      this.state.listAlarmInProgress ||
      this.state.getActualSurveillanceInProgress
    ) {
      return (
        <div className="loading" style={{ textAlign: "center" }}>
          <CircularLoading />
        </div>
      );
    } else {
      return (
        <AlarmChannelsTable
          surveillance={this.state.surveillance}
          alarm_name={this.state.current_alarm}
          delete_channel_callback={this.delete_channel_callback}
          match_alarm={false}
        />
      );
    }
  }

  renderLogButton() {
    let app = this.state.app;
    let environment = this.state.env;
    return (
      <Button
        size="small"
        color="primary"
        onClick={() => {
          let location =
            "https://search-es-logs-mk63vxc37emoqrbpxvhibmigt4.eu-west-1.es.amazonaws.com/_plugin/kibana/app/kibana#/dashboard/9a5a14a0-5e8b-11e8-a991-c133ab603076?_g=()&_a=(description:'',filters:!(('$state':(store:appState),meta:(alias:!n,controlledBy:'1527066704993',disabled:!f,index:eebd3040-6336-11e8-8cb4-bf99eef70bce,key:app_code.keyword,negate:!f,params:(query:" +
            app +
            ",type:phrase),type:phrase,value:" +
            app +
            "),query:(match:(app_code.keyword:(query:" +
            app +
            ",type:phrase)))),('$state':(store:appState),meta:(alias:!n,controlledBy:'1527082035146',disabled:!f,index:eebd3040-6336-11e8-8cb4-bf99eef70bce,key:env.keyword,negate:!f,params:(query:" +
            environment +
            ",type:phrase),type:phrase,value:" +
            environment +
            "),query:(match:(env.keyword:(query:" +
            environment +
            ",type:phrase))))),fullScreenMode:!f,options:(darkTheme:!f,hidePanelTitles:!f,useMargins:!t),panels:!((gridData:(h:6,i:'3',w:12,x:0,y:2),id:'72eb2bc0-5e8b-11e8-8cb4-bf99eef70bce',panelIndex:'3',type:search,version:'6.2.2'),(gridData:(h:2,i:'4',w:4,x:8,y:0),id:b98d7240-5e8b-11e8-a991-c133ab603076,panelIndex:'4',type:visualization,version:'6.2.2'),(gridData:(h:2,i:'5',w:8,x:0,y:0),id:'3729dc10-5e8d-11e8-8cb4-bf99eef70bce',panelIndex:'5',type:visualization,version:'6.2.2')),query:(language:lucene,query:''),timeRestore:!f,title:'Application%20message',viewMode:view)";
          window.open(location, "_blank");
        }}
      >
        Consulter les logs sur Kibana
      </Button>
    );
  }

  renderAddChannelDialog() {
    return (
      <AddChannelDialog
        account_id={this.state.account}
        account_list={this.props.account_list}
        user_name={this.props.user_name}
        userIdToken={this.state.userIdToken}
        visible={this.state.modalAddChannelOpen}
        onClose={this.handleAddChannelClose}
        env={this.state.env}
        app={this.state.app}
      />
    );
  }

  render() {
    const { classes } = this.props;

    if (this.state.noPermissions) {
      return (
        <div>
          <Grid item xs={12}>
            <Paper className={classes.paperAppSelect} elevation={1}>
              <Typography
                variant="h5"
                component="h2"
                style={{ paddingBottom: 40 }}
              >
                Vous ne devriez pas être ici.
              </Typography>
            </Paper>
          </Grid>
        </div>
      );
    }
    if (!this.state.noApps) {
      return (
        <div style={{ height: "80vh" }} className={classes.root}>
          <AppBar position="static" className={classes.dashboardAppBar}>
            <Tabs
              value={this.state.tab_index}
              onChange={this.handleTabChange}
              variant="scrollable"
            >
              <Tab icon={<ArrowBackIcon />} />
              <Tab label="État" />
              <Tab label="Alarmes" />
              <Tab label="Canaux d'Alerte" />
              <Tab label="Ressources" />
              <Tab label="Actions" />
              <Tab label="Logs" />
            </Tabs>
          </AppBar>
          {this.state.tab_index === 1 && (
            <div style={{ overflow: "auto", height: "inherit" }}>
              <Grid container spacing={24} className={classes.gridContainer}>
                <Grid item xs={12}>
                  <Paper className={classes.paperAppSelect} elevation={1}>
                    <Typography
                      variant="h5"
                      component="h2"
                      style={{ paddingBottom: 40 }}
                    >
                      Les procédures liées à l'application
                    </Typography>
                    {this.renderHeadline()}
                    <Typography variant="body1" style={{ paddingBottom: 40 }}>
                      Un problème? Vérifiez l'état des services AWS =>
                      <Button
                        size="small"
                        color="primary"
                        onClick={() => {
                          let location =
                            "https://phd.aws.amazon.com/phd/home#/dashboard/open-issues";
                          window.open(location, "_blank");
                        }}
                      >
                        Dashboard AWS
                      </Button>
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Paper className={classes.paperAppSelect} elevation={1}>
                  {/* <Typography variant="h5" component="h2" style={{paddingBottom:40}}>
                    L'état actuel pour <b>{this.state.app}/{this.state.env}</b>
                  </Typography> */}
                  {this.renderSimpleAlarmDashboard()}
                </Paper>
              </Grid>
            </div>
          )}
          {this.state.tab_index === 2 && (
            <div style={{ overflow: "auto", height: "inherit" }}>
              <Grid item xs={12}>
                <Paper className={classes.paperAppSelect} elevation={1}>
                  {this.renderAlarmDashboard()}
                </Paper>
              </Grid>
            </div>
          )}
          {this.state.tab_index === 3 && (
            <div style={{ overflow: "auto", height: "inherit" }}>
              <Grid container spacing={24} className={classes.gridContainer}>
                <Grid item xs={12}>
                  <Paper className={classes.paperAppSelect} elevation={1}>
                    <Typography
                      variant="h5"
                      component="h2"
                      style={{ paddingBottom: 40 }}
                    >
                      Les canaux d'alerte activés
                    </Typography>
                    <Button
                      size="small"
                      color="primary"
                      onClick={() => {
                        this.setState(
                          {
                            seconds: 0
                          },
                          () => {
                            this.setState(
                              {
                                seconds: 0
                              },
                              () => {
                                this.setState({
                                  getActualSurveillanceInProgress: true
                                });
                                this.getActualSurveillance(this.state.account);
                              }
                            );
                          }
                        );
                      }}
                    >
                      <Cached /> Rafraîchir ({this.state.seconds} s)
                    </Button>
                    {this.renderChannelsTable()}
                    {this.renderAddChannelDialog()}
                    <Button
                      onClick={() => this.handleAddChannelClickOpen()}
                      color="primary"
                      key="add"
                    >
                      Ajouter un canal
                    </Button>
                  </Paper>
                </Grid>
              </Grid>
            </div>
          )}
          {this.state.tab_index === 4 && (
            <div style={{ overflow: "auto", height: "inherit" }}>
              <Grid container spacing={24} className={classes.gridContainer}>
                <Grid item xs={12}>
                  <Paper className={classes.paperAppSelect} elevation={1}>
                    {this.renderResources()}
                  </Paper>
                </Grid>
              </Grid>
            </div>
          )}
          {this.state.tab_index === 5 && (
            <div style={{ overflow: "auto", height: "inherit" }}>
              <Grid container spacing={24} className={classes.gridContainer}>
                <Grid item xs={12}>
                  <Paper className={classes.paperAppSelect} elevation={1}>
                    {this.renderActions()}
                  </Paper>
                </Grid>
              </Grid>
            </div>
          )}
          {this.state.tab_index === 6 && (
            <div style={{ overflow: "auto", height: "inherit" }}>
              <Grid container spacing={24} className={classes.gridContainer}>
                <Grid item xs={12}>
                  <Paper className={classes.paperAppSelect} elevation={1}>
                    {this.renderLogButton()}
                  </Paper>
                </Grid>
              </Grid>
            </div>
          )}
        </div>
      );
    } else {
      return <Redirect to="/alerte-apps" />;
    }
  }
}

export default withRouter(withSnackbar(withStyles(styles)(LowLevelDashboard)));
