import API from "@aws-amplify/api/lib";

import { retrieveAllPerimeters } from "./PerimetersService";
import { getUserProfile } from "./UserProfilesService";

export const retrieveUserApplications = async userName => {
  const userProfile = await getUserProfile(userName);
  const perimeters = await retrieveAllPerimeters();
  return perimeters
    .filter(
      perimeter =>
        userProfile.Perimeters.indexOf(perimeter["Perimetre Name"]) > -1
    )
    .map(userPerimeter => JSON.parse(userPerimeter["Application Name"]))
    .flat(1);
};

export const deleteUser = async userName => {
  API.del("api-exsi-backend-v2", "/userProfiles/" + userName, {});
};
