import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";
import { CircularLoading } from "../../Styles/ComponentStyles";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { Button } from "@material-ui/core";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import { styles } from "../../Styles/ComponentStyles";
import { CustomTableCell } from "../../Styles/ComponentStyles";

import Config from "../../config.js";

// The order and what we decide to show to the user
const header_names = [
  "AccountID",
  "UserID",
  "AlarmName",
  "Channel",
  "TimeBegin",
  "TimeEnd",
  "OnCall"
];

class AlarmChannelsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      current_account_id: "",
      current_UUID: "",
      current_alarm_name: ""
    };
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
  }

  renderResourceRow(row) {
    let element = [];

    for (let header_name of header_names) {
      let row_value = "";
      if (header_name === "OnCall") {
        if (row.HO) {
          row_value = "HO";
        } else if (row.HNO) {
          row_value = "HNO";
        }
      }
      if (row.hasOwnProperty(header_name)) {
        row_value = row[header_name];
      }
      element.push(
        <CustomTableCell key={header_name}>{row_value}</CustomTableCell>
      );
    }

    element.push(
      <CustomTableCell key="delete-icon">
        <Button
          onClick={() =>
            this.setState({
              dialogOpen: true,
              current_account_id: row["AccountID"],
              current_UUID: row["UUID"],
              current_alarm_name: row["AlarmName"]
            })
          }
          color="primary"
          key="delete-channel"
        >
          <DeleteOutlinedIcon className={this.state.classes.icon} />
        </Button>
      </CustomTableCell>
    );
    return element;
  }

  renderResourceRows() {
    let element = [];
    if (this.state.surveillance && this.state.surveillance.Items.length > 0) {
      for (let key in this.state.surveillance.Items) {
        let regex = this.state.surveillance.Items[key]["AlarmName"].replace(
          "*",
          ".*"
        );

        // By default show all alarms unless we want to match the regex
        if (this.state.match_alarm) {
          if (this.state.alarm_name.match(regex)) {
            element.push(
              <TableRow key={key}>
                {this.renderResourceRow(this.state.surveillance.Items[key])}
              </TableRow>
            );
          }
        } else {
          element.push(
            <TableRow key={key}>
              {this.renderResourceRow(this.state.surveillance.Items[key])}
            </TableRow>
          );
        }
      }
    }
    return element;
  }

  /**
   * Render the header of the alarm channel tables
   * The order of the columns (and what header to show) are defined by the header_names array
   */
  renderResourceHeader() {
    let element = [];
    if (this.state.surveillance && this.state.surveillance.Items.length > 0) {
      for (let key in header_names) {
        let header = Config.translation[header_names[key]];
        element.push(<TableCell key={header}>{header}</TableCell>);
      }
      element.push(<TableCell key="Actions">Actions</TableCell>);
    } else {
      element.push(
        <TableCell variant="head" align="center" size="large" key="noChannel">
          Aucun canal trouvé
        </TableCell>
      );
    }
    return element;
  }

  handleCloseDialog() {
    this.setState({
      dialogOpen: false
    });
  }

  renderDialog() {
    if (this.state.dialogOpen) {
      return (
        <Dialog
          open={this.state.dialogOpen}
          onClose={this.handleCloseDialog}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Suppression canal</DialogTitle>
          <DialogContent>
            <DialogContentText>
              ATTENTION - Vous êtes sur le point de supprimer un canal
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseDialog} color="primary">
              Annuler
            </Button>
            <Button
              onClick={() =>
                this.state.delete_channel_callback(
                  this.state.current_account_id,
                  this.state.current_alarm_name,
                  this.state.current_UUID
                )
              }
              color="primary"
            >
              Valider
            </Button>
          </DialogActions>
        </Dialog>
      );
    }
  }

  render() {
    if (this.state.surveillance !== null) {
      return (
        <div>
          {this.renderDialog()}
          <Paper className="paperTable">
            <Table className="tableTable">
              <TableHead>
                <TableRow>{this.renderResourceHeader()}</TableRow>
              </TableHead>
              <TableBody>{this.renderResourceRows()}</TableBody>
            </Table>
          </Paper>
        </div>
      );
    } else {
      return <CircularLoading></CircularLoading>;
    }
  }
}

export default withStyles(styles)(AlarmChannelsTable);
