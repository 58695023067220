import API from "@aws-amplify/api/lib";

export const addLog = (userName, actionTime, actionDescription) =>
  API.post("api-exsi-backend", "/logs", {
    body: {
      userName,
      actionTime: actionTime.toString(),
      actionDescription
    }
  });

export const retrieveLogs = () => API.get("api-exsi-backend", "/logs", {});
