export const compareFunctionOnKey = (firstItemTags, secondItemTags, key) => {
  const hasFirstItemTagKey = firstItemTags.find(tag => tag["Key"] === key);
  const hasSecondItemTagKey = secondItemTags.find(tag => tag["Key"] === key);
  if (!(hasFirstItemTagKey || hasSecondItemTagKey)) {
    return 0;
  }
  if (!hasFirstItemTagKey) {
    return 1;
  }
  if (!hasSecondItemTagKey) {
    return -1;
  }
  return hasFirstItemTagKey.Value.toLowerCase() <
    hasSecondItemTagKey.Value.toLowerCase()
    ? -1
    : hasFirstItemTagKey.Value.toLowerCase() ===
      hasSecondItemTagKey.Value.toLowerCase()
    ? 0
    : 1;
};
