import React, { useCallback, useEffect, useState } from "react";
import { retrieveAllPerimeters } from "../../Services/PerimetersService";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import PerimetersManagementRow from "./PerimetersManagementRow";
import { CircularLoading } from "../../Styles/ComponentStyles";
import { Button } from "@material-ui/core";
import PerimetersManagementCreate from "./PerimetersManagementCreate";

const PerimetersManagementDashboard = ({ accountList }) => {
  const [perimeters, setPerimeters] = useState(undefined);
  const [isCreatePerimeterOpened, setIsCreatePerimeterOpened] = useState(false);
  const [applications] = useState(
    accountList
      .map(account => account["ACCOUNT_NAME"])
      .filter(accountName => accountName.includes("-"))
      .sort()
  );

  const handleCreatePerimeterClose = () => setIsCreatePerimeterOpened(false);

  useEffect(() => {
    const retrievePerimeters = async () => {
      setPerimeters(await retrieveAllPerimeters());
    };

    retrievePerimeters();
  }, []);

  const perimetersReloadCallBack = useCallback(async () => {
    setIsCreatePerimeterOpened(false);
    setPerimeters(undefined);
    setPerimeters(await retrieveAllPerimeters());
  }, []);

  return (
    <div>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell align="center" style={{ fontSize: "large" }}>
              <b>Périmètre</b>
            </TableCell>
            <TableCell align="center" style={{ fontSize: "large" }}>
              <b>Applications</b>
            </TableCell>
            <TableCell align="center" style={{ fontSize: "large" }}>
              <b>Téléphone astreinte</b>
            </TableCell>
            <TableCell align="center" style={{ fontSize: "large" }}>
              <b>Email</b>
            </TableCell>
            <TableCell align="center" style={{ fontSize: "large" }}>
              <b>Modifier</b>
            </TableCell>
            <TableCell align="center" style={{ fontSize: "large" }}>
              <b>Supprimer</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {perimeters &&
            perimeters.map((perimeter, index) => (
              <PerimetersManagementRow
                key={index}
                perimeter={perimeter}
                perimetersReloadCallBack={perimetersReloadCallBack}
                applications={applications}
              />
            ))}
        </TableBody>
      </Table>
      {!perimeters && (
        <div>
          <CircularLoading />
        </div>
      )}
      <Button
        color="primary"
        variant="contained"
        onClick={() => setIsCreatePerimeterOpened(true)}
      >
        Ajouter un périmètre
      </Button>
      <PerimetersManagementCreate
        open={isCreatePerimeterOpened}
        onClose={handleCreatePerimeterClose}
        onValidate={perimetersReloadCallBack}
        applications={applications}
      ></PerimetersManagementCreate>
    </div>
  );
};

export default PerimetersManagementDashboard;
