import React from "react";
import moment from "moment";
import "moment/locale/fr";
//import TableLogActions from '../Components/Tables/TableLogActions'
import CustomPaginationActionsTable from "../Components/Tables/TablePaginateLogActions";
import { retrieveLogs } from "../Services/LogsService";

//Set the fr language for moment
moment.locale("fr");

class Logs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dbItems: [],
      userIdToken: this.props.userIdToken
    };
    this.dbGet();
  }

  dbGet() {
    retrieveLogs().then(items => {
      this.setState({
        dbItems: items.sort((a, b) =>
          moment(b.USER_ACTION_TIME, "dddd Do MMMM YYYY H: mm: ss").format(
            "x"
          ) <
          moment(a.USER_ACTION_TIME, "dddd Do MMMM YYYY H: mm: ss").format("x")
            ? -1
            : 1
        )
      });
    });
  }

  render() {
    return (
      <div>
        <CustomPaginationActionsTable dbItems={this.state.dbItems} />
      </div>
    );
  }
}

export default Logs;
