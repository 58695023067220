import API from "@aws-amplify/api/lib";

export const isDutyAckNeeded = async userName => {
  const apiResult = await API.get("api-exsi-backend-v2", "/duty/start", {
    queryStringParameters: {
      user_name: userName
    }
  });
  return apiResult.isDutyAckNeeded;
};

export const updateDutyAck = async userName => {
  const apiResult = await API.post("api-exsi-backend-v2", "/duty/start", {
    queryStringParameters: {
      user_name: userName
    }
  });
  return apiResult.isDutyAckNeeded;
};

export const getDutyList = () => API.get("api-exsi-backend-v2", "/duty", {});

export const deleteDuty = (perimeterName, duty) =>
  API.del("api-exsi-backend-v2", "/duty", {
    body: {
      PerimeterName: perimeterName,
      Duty: duty
    }
  });

export const addDuty = (userId, perimeterName, duty) =>
  API.post("api-exsi-backend-v2", "/duty", {
    body: {
      PerimeterName: perimeterName,
      Duty: duty,
      UserId: userId
    }
  });

export const enrichDutyList = (dutyList, userProfiles) =>
  dutyList.map(duty => {
    const { UserID, ...dutyWithoutUserID } = duty;
    return {
      ...dutyWithoutUserID,
      UserProfile: userProfiles.find(
        userProfile => userProfile.UserName === duty.UserID
      )
    };
  });
