import React, { memo, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogContentText,
  ListItem,
  TextField
} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import List from "@material-ui/core/List";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import { putApplicationHeadline } from "../../Services/ApplicationService";

const HeadlineUpdate = memo(
  ({ open, onClose, onValidate, headlineInformation, selectedApplication }) => {
    const [
      updatedHeadlineInformation,
      setUpdatedHeadlineInformation
    ] = useState(headlineInformation);
    const [displayNotice, setDisplayNotice] = useState(false);
    const [disableValidate, setDisableValidate] = useState(false);

    const updateRow = (updatedValue, indexToUpdate, field) => {
      setUpdatedHeadlineInformation(
        updatedHeadlineInformation.map((row, index) =>
          indexToUpdate !== index
            ? row
            : {
                ...row,
                [field]: updatedValue
              }
        )
      );
    };

    useEffect(() => {
      const effectCheckAtLeastOneFilledField = index =>
        updatedHeadlineInformation[index].title ||
        updatedHeadlineInformation[index].linkName ||
        updatedHeadlineInformation[index].linkValue;

      const effectCheckLinkValueError = index =>
        !effectCheckAtLeastOneFilledField(index) ||
        (updatedHeadlineInformation[index].linkName &&
          !updatedHeadlineInformation[index].linkValue);

      const effectCheckLinkNameError = index =>
        !effectCheckAtLeastOneFilledField(index) ||
        (!updatedHeadlineInformation[index].linkName &&
          updatedHeadlineInformation[index].linkValue);

      const checkDisableValidate = () =>
        updatedHeadlineInformation.filter(
          (row, index) =>
            !effectCheckAtLeastOneFilledField(index) ||
            effectCheckLinkValueError(index) ||
            effectCheckLinkNameError(index)
        ).length > 0;
      if (updatedHeadlineInformation.length > 0) {
        setDisableValidate(checkDisableValidate());
      }
    }, [updatedHeadlineInformation]);

    const removeRow = indexToDelete => {
      setUpdatedHeadlineInformation(
        updatedHeadlineInformation
          .map((row, index) => (indexToDelete !== index ? row : null))
          .filter(row => row !== null)
      );
    };

    const addRow = () => {
      const newRow = {
        title: "",
        linkName: "",
        linkValue: ""
      };
      setUpdatedHeadlineInformation(
        updatedHeadlineInformation.length > 0
          ? [...updatedHeadlineInformation, newRow]
          : [newRow]
      );
    };

    const checkAtLeastOneFilledField = index =>
      updatedHeadlineInformation[index].title ||
      updatedHeadlineInformation[index].linkName ||
      updatedHeadlineInformation[index].linkValue;

    const checkLinkValueError = index =>
      !checkAtLeastOneFilledField(index) ||
      (updatedHeadlineInformation[index].linkName &&
        !updatedHeadlineInformation[index].linkValue);

    const checkLinkNameError = index =>
      !checkAtLeastOneFilledField(index) ||
      (!updatedHeadlineInformation[index].linkName &&
        updatedHeadlineInformation[index].linkValue);

    const handleValidate = async () => {
      await putApplicationHeadline(
        selectedApplication,
        updatedHeadlineInformation
      );
      onValidate();
    };

    const handleClose = () => {
      setDisableValidate(false);
      setDisplayNotice(false);
      setUpdatedHeadlineInformation(headlineInformation);
      onClose();
    };

    return (
      <Dialog open={open} onClose={handleClose} maxWidth="xl">
        <DialogTitle
          style={{
            backgroundColor: "#3f51b5",
            textAlign: "center"
          }}
        >
          <div style={{ color: "white" }}> Informations sur l'application</div>
        </DialogTitle>
        <DialogContent>
          <Button
            style={{ textAlign: "center" }}
            onClick={() => setDisplayNotice(!displayNotice)}
          >
            <b>
              {displayNotice ? (
                <ArrowDropDown fontSize="large" nativeColor="blue" />
              ) : (
                <ArrowDropUp fontSize="large" nativeColor="blue" />
              )}
            </b>
          </Button>
          {displayNotice && (
            <DialogContentText>
              Vous pouvez ajouter ou modifier une ligne d'information sur
              l'application. <br />
              Si vous décidez d'ajouter un lien à l'information, vous devrez
              remplir son nom et sa valeur.
              <br />
              Vous devez renseigner un titre et/ou un lien.
              <br />
              <br />
              Exemples d'information valide:
              <br />
              <br />
              Titre: Documentation ExSI -<br />
              Nom du lien: Confluence
              <br />
              Valeur du lien:
              https://confluence-grtgaz.atlassian.net/wiki/spaces/CCOE/pages/286818823/ExSI
              <br />
              <br />
              Titre: Documentation ExSI -<br />
              Nom du lien:
              <br />
              Valeur du lien:
              <br />
              <br />
              Titre:
              <br />
              Nom du lien: Confluence
              <br />
              Valeur du lien:
              https://confluence-grtgaz.atlassian.net/wiki/spaces/CCOE/pages/286818823/ExSI
              <br />
            </DialogContentText>
          )}
          <List>
            {updatedHeadlineInformation.length > 0 &&
              updatedHeadlineInformation.map((row, index) => (
                <ListItem key={index}>
                  <TextField
                    id={"title" + index}
                    label={"Titre"}
                    variant="outlined"
                    defaultValue={row.title}
                    onChange={event => {
                      updateRow(event.target.value, index, "title");
                    }}
                    fullWidth
                    error={!checkAtLeastOneFilledField(index)}
                    margin="dense"
                  />
                  <TextField
                    id={"linkName" + index}
                    label={"Nom du lien"}
                    variant="outlined"
                    defaultValue={row.linkName}
                    onChange={event => {
                      updateRow(event.target.value, index, "linkName");
                    }}
                    error={checkLinkNameError(index)}
                    fullWidth
                    margin="dense"
                  />
                  <TextField
                    id={"linkValue" + index}
                    label={"Valeur du lien"}
                    variant="outlined"
                    defaultValue={row.linkValue}
                    onChange={event => {
                      updateRow(event.target.value, index, "linkValue");
                    }}
                    error={checkLinkValueError(index)}
                    fullWidth
                    margin="dense"
                  />
                  <Button
                    onClick={() => {
                      removeRow(index);
                    }}
                  >
                    {" "}
                    <DeleteForeverOutlinedIcon color="primary" />
                  </Button>
                </ListItem>
              ))}
          </List>
          <Button
            style={{ float: "right" }}
            size="large"
            color="primary"
            variant="contained"
            onClick={() => {
              addRow();
            }}
          >
            <PlaylistAddIcon />
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant={"contained"}>
            <div
              style={{
                color: "white"
              }}
            >
              ANNULER
            </div>
          </Button>
          <Button
            onClick={handleValidate}
            disabled={disableValidate}
            variant={"contained"}
            style={{
              backgroundColor: "#32CD32"
            }}
          >
            <div
              style={{
                color: "white"
              }}
            >
              VALIDER
            </div>
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);

export default HeadlineUpdate;
