import React, { useEffect, useState } from "react";
import {
  getPipelineExecutions,
  getPipelineState
} from "../../Services/PipelinesService";
import { circleColor, CircularLoading } from "../../Styles/ComponentStyles";
import moment from "moment";
import { ArrowRightIcon } from "material-ui-pickers/_shared/icons/ArrowRightIcon";
import Circle from "../Geometric/Circle";
import Card from "@material-ui/core/Card";
import history from "../../history";
import Button from "@material-ui/core/Button";

const PipelineGeneral = ({ pipelineName, app, accountId }) => {
  const [pipelineState, setPipelineState] = useState(undefined);
  const [pipelineExecutions, setPipelineExecutions] = useState(undefined);

  useEffect(() => {
    const fetchPipelineState = async () => {
      setPipelineState(await getPipelineState(pipelineName, accountId));
    };

    fetchPipelineState();
  }, [accountId, pipelineName]);

  useEffect(() => {
    async function fetchPipelineExecutions() {
      setPipelineExecutions(
        await getPipelineExecutions(pipelineName, accountId)
      );
    }

    fetchPipelineExecutions();
  }, [accountId, pipelineName]);

  const renderCircle = status => {
    switch (status) {
      case "InProgress":
        return circleColor.blue;
      case "Succeeded":
        return circleColor.green;
      case "Failed":
        return circleColor.red;
      case "Stopped":
        return circleColor.amber;
      default:
        return circleColor.gray;
    }
  };

  return pipelineExecutions && pipelineState ? (
    <Card style={{ padding: "1em", margin: "3em" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap"
        }}
      >
        <div>
          <div style={{ display: "flex", justifyContent: "left" }}>
            <div style={{ marginRight: "0.5em" }}>
              <Circle
                bgColor={renderCircle(
                  pipelineExecutions.pipelineExecutionSummaries[0]
                    ? pipelineExecutions.pipelineExecutionSummaries[0].status
                    : ""
                )}
                circleSize={35}
              />
            </div>
            <div style={{ fontSize: "x-large" }}>
              <b>{pipelineName}</b>
            </div>
          </div>
          {pipelineExecutions.pipelineExecutionSummaries[0] && (
            <div>
              Dernière exécution:{" "}
              {moment(
                pipelineExecutions.pipelineExecutionSummaries[0].lastUpdateTime
              ).format("ddd Do MMMM YYYY, HH:mm:ss")}
            </div>
          )}
        </div>
        <div style={{ fontSize: "large", marginTop: "1em" }}>
          <b>{pipelineState.stageStates.length}</b> étapes de déploiement
        </div>
        <div>
          <Button
            onClick={() => {
              history.push({
                pathname: "/pipeline/" + pipelineName,
                state: {
                  app,
                  accountId
                }
              });
            }}
          >
            <ArrowRightIcon
              style={{ transform: "scale(2.5)", marginTop: "0.25em" }}
              fontSize={"large"}
              nativeColor={"green"}
            />
          </Button>
        </div>
      </div>
    </Card>
  ) : (
    <div>
      <CircularLoading />
    </div>
  );
};

export default PipelineGeneral;
