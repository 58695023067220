import React, { useEffect, useState } from "react";
import { retrieveAdmins } from "../../Services/AdminService";
import { CircularLoading } from "../../Styles/ComponentStyles";
import UsersManagementDashboard from "./UsersManagementDashboard";
import Card from "@material-ui/core/Card";

const UsersManagementGeneral = ({ userName }) => {
  const [isUserAdmin, setIsUserAdmin] = useState(undefined);

  useEffect(() => {
    const retrieveUserAdmins = async () => {
      const userAdmins = await retrieveAdmins("user");
      setIsUserAdmin(userAdmins.indexOf(userName) > -1);
    };

    retrieveUserAdmins();
  }, [userName]);
  return isUserAdmin !== undefined ? (
    isUserAdmin ? (
      <div>
        <div style={{ fontSize: "x-large", marginTop: "1em" }}>
          <b>GESTION DES UTILISATEURS</b>
        </div>
        <Card>
          <UsersManagementDashboard />
        </Card>
      </div>
    ) : (
      <div>
        {" "}
        Vous n'avez pas le droit d'accéder à cette page. Contacter un
        administrateur
      </div>
    )
  ) : (
    <CircularLoading />
  );
};

export default UsersManagementGeneral;
