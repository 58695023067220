import React from "react";
import { Button, TableBody, TableCell, TableHead } from "@material-ui/core";
import { CircularLoading, styles } from "../../Styles/ComponentStyles";
import "moment/locale/fr";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import { withSnackbar } from "notistack";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";

import { withStyles } from "@material-ui/core/styles";
import { realizeAction } from "../../Helpers/realize_action";
import moment from "moment";
import { addLog } from "../../Services/LogsService";
import config from "../../config";
import { putAccountState } from "../../Services/AccountService";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";

class LambdaActionsGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      open: false,
      response_element: [],
      response: {},
      selected: {},
      inputs: {},
      getLambdaListInProgress: false,
      formValidated: false,
      actionLaunched: false,
      actionEnded: false,
      actionResult: "",
      displayDependentParameter: false,
      responseDependentParameter: undefined,
      responseKeyDependentParameter: undefined,
      responseKeyListDependentParameter: undefined,
      dependentParameterName: undefined
    };
    this.getLambdaListCallback = this.getLambdaListCallback.bind(this);
    this.invokeCallback = this.invokeCallback.bind(this);
    this.getParameterList = this.getParameterList.bind(this);
    this.renderParameters = this.renderParameters.bind(this);
    this.getParameterCallback = this.getParameterCallback.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.account_id && nextProps.userIdToken) {
      this.setState(
        {
          ...nextProps
        },
        () => {
          if (!this.state.functions) {
            this.getLambdaList();
          }
        }
      );
    }
  }

  getLambdaListCallback(response) {
    let functions_array = [];
    response.forEach(element => {
      if ("Environment" in element) {
        if ("Variables" in element.Environment) {
          if ("EXSI" in element.Environment.Variables) {
            functions_array.push(element);
          }
        }
      }
    });
    this.setState({
      functions: functions_array.sort((a, b) => {
        const functionNameA = this.retrieveFunctionName(a).toLowerCase();
        const functionNameB = this.retrieveFunctionName(b).toLowerCase();
        return functionNameA > functionNameB
          ? 1
          : functionNameB > functionNameA
          ? -1
          : 0;
      }),
      getLambdaListInProgress: false
    });
  }

  retrieveFunctionName = action =>
    action.Environment.Variables.EXSI === "true" ||
    action.Environment.Variables.EXSI === "True"
      ? action.FunctionName
      : action.Environment.Variables.EXSI;

  getLambdaList() {
    if (this.state.getLambdaListInProgress) {
      return;
    }
    this.setState(
      {
        getLambdaListInProgress: true
      },
      () => {
        realizeAction(
          this.state.userIdToken,
          this.state.account_id,
          "lambda",
          "list_functions",
          null,
          null,
          null,
          this.getLambdaListCallback,
          "Marker",
          "NextMarker",
          null,
          [],
          "Functions"
        );
      }
    );
  }

  handleClickOpen = (
    function_name,
    function_arn,
    function_real_name,
    function_notice,
    function_exec_mode
  ) => {
    this.setState(
      {
        open: true,
        function_name: function_name,
        function_arn: function_arn,
        action_name: function_real_name,
        function_notice: function_notice,
        function_exec_mode: function_exec_mode || "SYNC"
      },
      () => {
        this.getParameterList();
      }
    );
  };

  handleClose = () => {
    this.setState({
      open: false,
      actionLaunched: false,
      actionEnded: false,
      actionResult: "",
      selected: {},
      inputs: {},
      response: {},
      response_element: []
    });
  };

  async invokeCallback(response) {
    if (this.state.function_exec_mode === "SYNC") {
      if (response.StatusCode === 200) {
        if (this.state.function_name.startsWith("laf-env-controller")) {
          const isStart = this.state.function_name.endsWith("start");
          await putAccountState(this.state.account_id, isStart ? "up" : "down");
          const startMessage = isStart ? "Démarrage" : "Arrêt";
          const actionDescription =
            startMessage +
            " de " +
            this.state.app +
            "-" +
            this.state.env +
            " (" +
            this.state.account_id +
            ").";
          addLog(this.state.userName, moment().unix() - 1, actionDescription);
        }
        this.setState({
          actionEnded: true,
          actionResult: "Action réalisée avec succès: " + response.Payload
        });
      } else {
        this.setState({
          actionEnded: true,
          actionResult:
            "Problème lors de l'éxecution de l'action: " + response.Payload
        });
      }
      const actionDescription =
        "Résultat de la lambda (mode synchrone) " +
        this.state.function_name +
        " sur " +
        this.state.app +
        "-" +
        this.state.env +
        " (" +
        this.state.account_id +
        ").";
      addLog(
        this.state.userName,
        moment().unix(),
        JSON.stringify({
          logDescription: actionDescription,
          response
        })
      );
    } else {
      this.setState({
        actionEnded: true,
        actionResult: this.state.function_notice
      });
    }
    this.cleanDependentParameterState();
  }

  handleValidate = () => {
    if (
      this.state.validation_value ===
      this.state.good_function_name_validation_value
    ) {
      const actionDescription =
        "Déclenchement de la lambda " +
        this.state.function_name +
        " sur " +
        this.state.app +
        "-" +
        this.state.env +
        " (" +
        this.state.account_id +
        ")";
      addLog(this.state.userName, moment().unix(), actionDescription);
      realizeAction(
        this.props.userIdToken,
        this.state.account_id,
        "lambda",
        "invoke",
        this.state.function_name,
        "FunctionName",
        false,
        this.invokeCallback,
        null,
        null,
        null,
        null,
        null,
        "eu-west-1",
        {
          Payload: JSON.stringify(
            Object.assign(this.state.selected, this.state.inputs)
          ),
          InvocationType:
            this.state.function_exec_mode === "SYNC"
              ? "RequestResponse"
              : "Event"
        }
      );
    } else {
      console.log("Wrong code");
    }
    this.setState({
      actionLaunched: true
    });
  };

  handleTextFieldChange = (e, validation_value) => {
    this.setState(
      {
        [validation_value]: e.target.value
      },
      () => {
        if (
          this.state.validation_function_name_value ===
            this.state.function_name &&
          this.state.validation_env_value === this.state.env &&
          this.state.validation_app_value === this.state.app
        ) {
          this.setState({
            formValidated: true
          });
        } else {
          this.setState({
            formValidated: false
          });
        }
      }
    );
  };

  renderButton(row) {
    const function_name = this.retrieveFunctionName(row);
    return (
      <Button
        onClick={() =>
          this.handleClickOpen(
            row.FunctionName,
            row.FunctionArn,
            function_name,
            row.Environment.Variables.EXSI_NOTICE,
            row.Environment.Variables.EXSI_EXEC_MODE
          )
        }
        color="primary"
        key="button"
      >
        {function_name}
      </Button>
    );
  }

  getParameterCallback(response) {
    let final_parameter = Object.assign({}, this.state.response, response);
    this.setState({
      response: final_parameter
    });
  }

  getParameterList() {
    if (Object.keys(this.state.response).length === 0) {
      this.state.functions.forEach(element => {
        if (element.FunctionArn === this.state.function_arn) {
          if ("Environment" in element) {
            if ("Variables" in element.Environment) {
              for (let key in element.Environment.Variables) {
                if (key.startsWith("param_")) {
                  let service = element.Environment.Variables[key].split(
                    "::"
                  )[0];
                  let call = element.Environment.Variables[key].split("::")[1];
                  if (
                    element.Environment.Variables[key].split("::").length === 5
                  ) {
                    this.state.response_element.push(
                      key.split("_")[1] +
                        "::" +
                        element.Environment.Variables[key].split("::")[2] +
                        "::" +
                        element.Environment.Variables[key].split("::")[3] +
                        "::" +
                        element.Environment.Variables[key].split("::")[4]
                    );
                  } else {
                    this.state.response_element.push(
                      key.split("_")[1] +
                        "::" +
                        element.Environment.Variables[key].split("::")[2] +
                        "::" +
                        element.Environment.Variables[key].split("::")[3]
                    );
                  }
                  const body =
                    service === "ssm" && call === "list_documents"
                      ? {
                          DocumentFilterList: [
                            {
                              key: "Owner",
                              value: "Self"
                            }
                          ],
                          MaxResults: 50
                        }
                      : null;
                  realizeAction(
                    this.props.userIdToken,
                    this.state.account_id,
                    service,
                    call,
                    null,
                    null,
                    false,
                    this.getParameterCallback,
                    null,
                    null,
                    null,
                    null,
                    null,
                    "eu-west-1",
                    body
                  );
                }
                if (key.startsWith("input_")) {
                  this.setState({
                    inputs: Object.assign(this.state.inputs, {
                      [key.split("input_")[1]]:
                        element.Environment.Variables[key]
                    })
                  });
                }
              }
            }
          }
        }
      });
    }
  }

  handleParameterChange = event => {
    const value = event.target.value;
    if (
      this.state.function_name === "laf-exsi-batch-queue-controller-disable" ||
      this.state.function_name === "laf-exsi-batch-queue-controller-enable"
    ) {
      this.setState({
        selected: {
          queueArn:
            value === "allQueues"
              ? this.state.response.jobQueues.map(
                  jobQueue => jobQueue.jobQueueArn
                )
              : [value]
        }
      });
    } else {
      const temp_state = this.state.selected;
      temp_state[event.target.id] = value;
      this.setState({
        selected: temp_state
      });
      this.getDependentParameter();
    }
  };

  handleInputsChange = event => {
    this.setState({
      inputs: Object.assign(this.state.inputs, {
        [event.target.id]: event.target.value
      })
    });
  };

  renderParameterOptions(select, param, sub_element) {
    let response_param = param;
    let response_element = sub_element;
    if (select.split("::").length === 4) {
      response_param =
        param.length > 0
          ? param.map(currentParam => currentParam[select.split("::")[2]][0])
          : [];
      response_element = select.split("::")[3];
    }
    if (
      select.split("::").length === 3 &&
      select.split("::")[2] === "undefined"
    ) {
      response_param = param;
      response_element = undefined;
    }
    let optionList;
    if (
      this.state.function_name === "laf-exsi-ec2-controller-stop" ||
      this.state.function_name === "laf-exsi-ec2-controller-start"
    ) {
      optionList = response_param
        .filter(option =>
          option.Tags.find(
            tag =>
              tag.Key === config.actionStopStartEc2.tagName &&
              tag.Value === "true"
          )
        )
        .map(option => {
          const tagName = option.Tags.find(tag => tag.Key === "Name");
          const name = tagName ? tagName.Value : "";
          return (
            <option
              key={option[response_element]}
              value={option[response_element]}
            >
              {name} {name !== "" && "("}
              {option[response_element]}
              {name !== "" && ")"}
            </option>
          );
        });
    } else if (response_element) {
      optionList = response_param.map(option => (
        <option key={option[response_element]} value={option[response_element]}>
          {option[response_element]}
        </option>
      ));
    } else {
      optionList = response_param.map(option => (
        <option key={option} value={option}>
          {option}
        </option>
      ));
    }
    if (
      this.state.function_name === "laf-exsi-batch-queue-controller-disable" ||
      this.state.function_name === "laf-exsi-batch-queue-controller-enable"
    ) {
      if (optionList && optionList.length > 0) {
        optionList.unshift(
          <option key="allQueues" value="allQueues">
            Toutes les job queues
          </option>
        );
      }
    }
    optionList.unshift(
      <option key="Sélectionner une valeur" value="Sélectionner une valeur">
        Sélectionner une valeur
      </option>
    );
    return [optionList];
  }

  renderParameters() {
    if (Object.keys(this.state.response).length !== 0) {
      let selectList = this.state.response_element.map(select => {
        let param_name = select.split("::")[0];
        let root_element = select.split("::")[1];
        let sub_element = select.split("::")[2];
        if (root_element in this.state.response) {
          return (
            <div>
              <br />
              <section key={root_element}>
                <FormControl key={root_element}>
                  <InputLabel htmlFor={root_element}>
                    {select.split("::").length === 4
                      ? select.split("::")[2]
                      : root_element}
                  </InputLabel>
                  <Select
                    native
                    key={root_element}
                    value={this.state.selected[root_element]}
                    id={param_name}
                    onChange={this.handleParameterChange}
                  >
                    {this.renderParameterOptions(
                      select,
                      this.state.response[root_element],
                      sub_element
                    )}
                  </Select>
                </FormControl>
              </section>
            </div>
          );
        }
        return "";
      });
      return [selectList];
    }
  }

  getDependentParameter() {
    const currentFunction = this.state.functions.find(
      element => element.FunctionArn === this.state.function_arn
    );
    if ("Environment" in currentFunction) {
      if ("Variables" in currentFunction.Environment) {
        for (let key in currentFunction.Environment.Variables) {
          if (key.startsWith("dependent_param_")) {
            const dependentService = currentFunction.Environment.Variables[
              key
            ].split("::")[0];
            const service = currentFunction.Environment.Variables[key].split(
              "::"
            )[1];
            const call = currentFunction.Environment.Variables[key].split(
              "::"
            )[2];
            const responseKey = currentFunction.Environment.Variables[
              key
            ].split("::")[3];
            const responseKeyList = currentFunction.Environment.Variables[
              key
            ].split("::")[4];
            if (
              this.state.selected.hasOwnProperty(dependentService) &&
              this.state.selected[dependentService] !==
                "Sélectionner une valeur"
            ) {
              realizeAction(
                this.props.userIdToken,
                this.state.account_id,
                service,
                call,
                null,
                null,
                false,
                response => {
                  this.setState({
                    responseDependentParameter: response,
                    responseKeyDependentParameter: responseKey,
                    responseKeyListDependentParameter: responseKeyList,
                    dependentParameterName: key.split("_")[2],
                    displayDependentParameter: true
                  });
                },
                null,
                null,
                null,
                null,
                null,
                "eu-west-1",
                {
                  [dependentService]: this.state.selected[dependentService]
                }
              );
            } else {
              this.cleanDependentParameterState();
            }
          }
        }
      }
    }
  }

  renderDependentParameter = () => (
    <div>
      <br />
      <section key={this.state.dependentParameterName}>
        <FormControl key={this.state.dependentParameterName}>
          <InputLabel htmlFor={this.state.dependentParameterName}>
            {this.state.responseKeyListDependentParameter
              ? this.state.responseKeyListDependentParameter
              : this.state.responseKeyDependentParameter}
          </InputLabel>
          <Select
            native
            key={this.state.dependentParameterName}
            value={this.state.selected[this.state.dependentParameterName]}
            id={this.state.dependentParameterName}
            onChange={this.handleParameterChange}
          >
            {this.renderDependentParameterOptions(
              this.state.responseDependentParameter[
                this.state.responseKeyDependentParameter
              ],
              this.state.responseKeyListDependentParameter
            )}
          </Select>
        </FormControl>
      </section>
    </div>
  );

  renderDependentParameterOptions = (options, responseKeyList) => {
    let optionList;
    if (responseKeyList) {
      optionList = options.map(option => (
        <option key={option[responseKeyList]} value={option[responseKeyList]}>
          {option[responseKeyList]}
        </option>
      ));
    } else {
      optionList = options.map(option => (
        <option key={option} value={option}>
          {option}
        </option>
      ));
    }
    optionList.unshift(
      <option key="Sélectionner une valeur" value="Sélectionner une valeur">
        Sélectionner une valeur
      </option>
    );

    return [optionList];
  };

  cleanDependentParameterState = () => {
    this.setState({
      responseDependentParameter: undefined,
      responseKeyDependentParameter: undefined,
      responseKeyListDependentParameter: undefined,
      dependentParameterName: undefined,
      displayDependentParameter: false
    });
  };

  renderInputs() {
    return Object.keys(this.state.inputs).map(input => (
      <div>
        <br />
        <TextField
          id={input}
          label={input}
          variant="outlined"
          defaultValue={this.state.inputs[input]}
          onChange={this.handleInputsChange}
          fullWidth
          margin="dense"
        />
      </div>
    ));
  }

  renderDialogContent() {
    return (
      <div>
        <DialogContent>
          <DialogContentText>
            ATTENTION - Vous êtes sur le point de réaliser{" "}
            <b>{this.state.action_name}</b> sur le compte{" "}
            <b>
              {this.state.account_id}({this.state.app}-{this.state.env})
            </b>
            . <br />
            Pour valider, veuillez remplir ces trois champs ci-dessous. L'action
            sera réalisée dès que vous cliquerez sur "Valider".
            <br />
            <br />
            <u>{this.state.function_notice}</u>
          </DialogContentText>
          <br />
          <br />
          <DialogContentText>
            <b> {this.state.app}</b>
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label={"Confirmation de l'application: " + this.state.app}
            type="text"
            onChange={event =>
              this.handleTextFieldChange(event, "validation_app_value")
            }
            fullWidth
          />
          <br />
          <br />
          <DialogContentText>
            <b> {this.state.env}</b>
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label={"Confirmation de l'environement: " + this.state.env}
            type="text"
            onChange={event =>
              this.handleTextFieldChange(event, "validation_env_value")
            }
            fullWidth
          />
          <br />
          <br />
          <DialogContentText>
            <b> {this.state.function_name}</b>
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label={"Confirmation de l'action: " + this.state.function_name}
            type="text"
            onChange={event =>
              this.handleTextFieldChange(
                event,
                "validation_function_name_value"
              )
            }
            fullWidth
          />
          <br />
          <br />
          <form autoComplete="off">
            {this.renderParameters()}
            {this.state.displayDependentParameter &&
              this.renderDependentParameter()}
            {this.renderInputs()}
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary">
            Annuler
          </Button>
          <Button
            onClick={this.handleValidate}
            color="primary"
            disabled={!this.state.formValidated}
          >
            Valider
          </Button>
        </DialogActions>
      </div>
    );
  }

  renderDialogContentDuringAction() {
    return (
      <div>
        <DialogContent>
          <DialogContentText>
            Vous venez de lancer <b>{this.state.action_name}</b> sur le compte{" "}
            <b>
              {this.state.account_id}({this.state.app}-{this.state.env})
            </b>
          </DialogContentText>

          <DialogContentText>
            <br />
            {this.state.function_exec_mode === "SYNC" && (
              <div>
                <b>Résultats de l'action lancée:</b>
              </div>
            )}
            <br />
            {this.state.actionEnded ? (
              <div style={{ whiteSpace: "pre-line" }}>
                {this.state.actionResult}
              </div>
            ) : (
              <CircularLoading />
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {this.state.actionEnded && (
            <Button onClick={this.handleClose} color="primary">
              OK
            </Button>
          )}
        </DialogActions>
      </div>
    );
  }

  render() {
    if (this.state.functions) {
      return (
        <div>
          <Dialog
            open={this.state.open}
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title"
            disableBackdropClick={
              this.state.actionLaunched && !this.state.actionEnded
            }
            maxWidth="xl"
          >
            <DialogTitle id="form-dialog-title">
              {this.state.action_name}
            </DialogTitle>
            {!this.state.actionLaunched && this.renderDialogContent()}
            {this.state.actionLaunched &&
              this.renderDialogContentDuringAction()}
          </Dialog>
          {
            <Table>
              <TableHead>
                <TableCell>Nom</TableCell>
                <TableCell>Description</TableCell>
              </TableHead>
              <TableBody>
                {this.state.functions.map(row => (
                  <TableRow>
                    <TableCell>{this.renderButton(row)}</TableCell>
                    <TableCell>
                      {row.Description || <i>No description available</i>}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          }
        </div>
      );
    } else {
      return <CircularLoading />;
    }
  }
}

export default withSnackbar(withStyles(styles)(LambdaActionsGrid));
