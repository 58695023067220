import React, { useState } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Button, Dialog, DialogContent } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import { CircularLoading } from "../../Styles/ComponentStyles";
import moment from "moment";
import { addLog } from "../../Services/LogsService";
import { getCurrentUserProfile } from "../../Services/AuthService";
import TextField from "@material-ui/core/TextField";
import { addPerimeter } from "../../Services/PerimetersService";
import Grid from "@material-ui/core/Grid";

const PerimetersManagementUpdate = ({
  open,
  onClose,
  onValidate,
  perimeter,
  applications
}) => {
  const [
    perimeterDutyPhoneNumberToUpdate,
    setPerimeterDutyPhoneNumberToUpdate
  ] = useState(perimeter["DutyPhoneNumber"]);
  const [perimeterEmailToUpdate, setPerimeterEmailToUpdate] = useState(
    perimeter["Email"]
  );
  const [
    perimeterApplicationsToUpdate,
    setPerimeterApplicationsToUpdate
  ] = useState(JSON.parse(perimeter["Application Name"]));
  const [isUpdateInProgress, setIsUpdateInProgress] = useState(false);

  const handleApplicationListChange = event => {
    const selectedApplication = event.target.value;
    if (event.target.checked) {
      setPerimeterApplicationsToUpdate([
        ...perimeterApplicationsToUpdate,
        selectedApplication
      ]);
    } else {
      setPerimeterApplicationsToUpdate(
        perimeterApplicationsToUpdate.filter(
          application => application !== selectedApplication
        )
      );
    }
  };

  const handleClose = () => {
    cleanState();
    onClose();
  };

  const handleValidate = async () => {
    setIsUpdateInProgress(true);
    await addPerimeter(
      perimeter["Perimetre Name"],
      JSON.stringify(perimeterApplicationsToUpdate),
      perimeterDutyPhoneNumberToUpdate,
      perimeterEmailToUpdate
    );
    const currentUser = await getCurrentUserProfile();
    logUpdate(currentUser);
    cleanState();
    onValidate();
  };

  const logUpdate = currentUser => {
    const currentTime = moment().unix();
    const actionDescription =
      "Mise à jour du périmètre " + perimeter["Perimetre Name"];
    addLog(currentUser.UserName, currentTime, actionDescription);
  };

  const cleanState = () => {
    setPerimeterEmailToUpdate("");
    setPerimeterDutyPhoneNumberToUpdate(perimeter["DutyPhoneNumber"]);
    setPerimeterApplicationsToUpdate(JSON.parse(perimeter["Application Name"]));
    setIsUpdateInProgress(false);
    setIsUpdateInProgress(false);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle
        style={{
          backgroundColor: "#3f51b5",
          textAlign: "center"
        }}
      >
        <div style={{ color: "white" }}>
          Modifier le périmètre {perimeter["Perimetre Name"]}
        </div>
      </DialogTitle>
      {!isUpdateInProgress ? (
        <DialogContent>
          <TextField
            id={"perimeterDutyPhoneNumber"}
            label={
              "Numéro de téléphone d'astreinte du périmètre. Format= +33XXXXXXXXX"
            }
            variant="outlined"
            defaultValue={perimeter["DutyPhoneNumber"]}
            error={!/\+33[0-9]{9}$/g.test(perimeterDutyPhoneNumberToUpdate)}
            onChange={event => {
              setPerimeterDutyPhoneNumberToUpdate(event.target.value);
            }}
            fullWidth
            margin="dense"
          />
          <TextField
            id={"perimeterEmail"}
            label={"Email du périmètre"}
            variant="outlined"
            defaultValue={perimeter["Email"]}
            onChange={event => {
              setPerimeterEmailToUpdate(event.target.value);
            }}
            fullWidth
            margin="dense"
          />
          {applications.map(application => (
            <Grid item key={application}>
              <label key={application}>
                <input
                  name={application}
                  value={application}
                  type="checkbox"
                  checked={
                    perimeterApplicationsToUpdate.indexOf(application) > -1
                  }
                  onChange={handleApplicationListChange}
                />
                {application}
              </label>
            </Grid>
          ))}
        </DialogContent>
      ) : (
        <CircularLoading />
      )}
      <DialogActions>
        <Button
          onClick={handleClose}
          variant={"contained"}
          disabled={isUpdateInProgress}
        >
          <div
            style={{
              color: "white"
            }}
          >
            Annuler
          </div>
        </Button>
        <Button
          onClick={handleValidate}
          variant={"contained"}
          style={{
            backgroundColor: "#32CD32"
          }}
          disabled={
            isUpdateInProgress ||
            !/\+33[0-9]{9}$/g.test(perimeterDutyPhoneNumberToUpdate)
          }
        >
          <div
            style={{
              color: "white"
            }}
          >
            Valider
          </div>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PerimetersManagementUpdate;
