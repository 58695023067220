import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { withStyles } from "@material-ui/core/styles";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MoreIcon from "@material-ui/icons/MoreVert";
import { Auth } from "aws-amplify";
import moment from "moment";
import "moment/locale/fr";

import history from "../../history";
import SimpleMenu from "../Menus/SimpleMenu.js";
import { styles } from "../../Styles/ComponentStyles";
import Button from "@material-ui/core/Button";
import { isDutyAckNeeded, updateDutyAck } from "../../Services/DutyService";
import { addLog } from "../../Services/LogsService";

//Set the fr language for moment
moment.locale("fr");

class PrimarySearchAppBar extends React.Component {
  state = {
    anchorEl: null,
    mobileMoreAnchorEl: null,
    appName: "ExSI",
    selected_application: this.props.selected_application,
    environment: this.props.environment,
    userFirstName: this.props.userFirstName,
    userLastName: this.props.userLastName,
    isDutyAckNeeded: false
  };

  componentDidMount() {
    isDutyAckNeeded(this.props.user_name)
      .then(response =>
        this.setState({
          isDutyAckNeeded: response
        })
      )
      .catch(err => console.log(err));
  }

  componentWillReceiveProps(props) {
    this.setState({
      selected_application: props.selected_application,
      environment: props.environment
    });
  }

  handleProfileMenuOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
    this.handleMobileMenuClose();
  };

  handleMobileMenuOpen = event => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  };

  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null });
  };

  handleDutyClick = () =>
    updateDutyAck(this.props.user_name).then(response => {
      addLog(
        "ExSI-Prise-Astreinte",
        moment().unix(),
        "Astreinte confirmée depuis l'interface web par l'utilisateur " +
          this.props.user_name
      );
      this.setState({
        isDutyAckNeeded: response
      });
    });

  render() {
    const { anchorEl, mobileMoreAnchorEl } = this.state;
    const { classes } = this.props;
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const renderMenu = (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMenuOpen}
        onClose={this.handleMenuClose}
      >
        <MenuItem
          onClick={() => {
            this.handleMenuClose();
            history.push({
              pathname: "/profil-utilisateur"
            });
          }}
        >
          Profil utilisateur
        </MenuItem>
        <MenuItem
          onClick={() => {
            sessionStorage.setItem("authenticated", false);
            this.handleMenuClose();
            Auth.signOut({ global: true })
              .then(data => {
                window.location.reload();
                console.log(data);
              })
              .catch(err => console.log(err));
          }}
        >
          Déconnexion
        </MenuItem>
      </Menu>
    );
    const renderMobileMenu = (
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMobileMenuOpen}
        onClose={this.handleMobileMenuClose}
      >
        <MenuItem onClick={this.handleProfileMenuOpen}>
          <IconButton color="inherit">
            <AccountCircle />
          </IconButton>
          <p>Profil</p>
        </MenuItem>
      </Menu>
    );

    let full_environment = "";
    if (this.state.environment === "prd") {
      full_environment = "PRODUCTION";
    } else if (this.state.environment === "stg") {
      full_environment = "STAGING";
    } else if (this.state.environment === "dev") {
      full_environment = "DEVELOPMENT";
    }

    return (
      <div className={classes.rootSearchBar}>
        <AppBar position="static" style={{ backgroundColor: "#00a984" }}>
          <Toolbar>
            <IconButton
              className={classes.menuButtonSearchBar}
              color="inherit"
              aria-label="Open drawer"
            >
              <SimpleMenu
                handleLeftMenuClick={this.props.handleLeftMenuClick}
                user_name={this.props.user_name}
                handleApplicationChange={this.props.handleApplicationChange}
              />
            </IconButton>
            {this.state.selected_application !== null &&
            full_environment !== "" ? (
              <Typography
                className={classes.titleSearchBar}
                variant="h6"
                color="inherit"
                noWrap
              >
                {this.state.appName +
                  " - Application " +
                  this.state.selected_application.toUpperCase() +
                  " - " +
                  full_environment}
              </Typography>
            ) : (
              <Typography
                className={classes.titleSearchBar}
                variant="h6"
                color="inherit"
                noWrap
              >
                {this.state.appName}
              </Typography>
            )}
            <div className={classes.growSearchBar}>
              {this.state.isDutyAckNeeded ? (
                <Button
                  onClick={this.handleDutyClick}
                  variant="contained"
                  color="secondary"
                  className={classes.button}
                  style={{ justifyContent: "center" }}
                >
                  Confirmer mon astreinte
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  style={{ justifyContent: "center" }}
                  disabled={true}
                >
                  Aucune astreinte à confirmer
                </Button>
              )}
            </div>
            <div className={classes.sectionDesktopSearchBar}>
              <p>
                {this.state.userFirstName} {this.state.userLastName}
              </p>
              <IconButton
                aria-owns={isMenuOpen ? "material-appbar" : null}
                aria-haspopup="true"
                onClick={this.handleProfileMenuOpen}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
            </div>
            <div className={classes.sectionMobileSearchBar}>
              <IconButton
                aria-haspopup="true"
                onClick={this.handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        {renderMenu}
        {renderMobileMenu}
      </div>
    );
  }
}

export default withStyles(styles)(PrimarySearchAppBar);
