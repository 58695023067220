import React from "react";
import AppCard from "./AppCard";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

import history from "../../history";

class AppCardList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      account_list: props.account_list,
      app: props.app,
      classes: props.classes,
      grid_direction: props.grid_direction,
      app_clickable: props.app_clickable
    };
  }

  renderEnv(env) {
    if (this.state.app + "-" + env in this.state.account_list) {
      return (
        <AppCard
          app={this.state.app}
          environment={env}
          account_id={this.state.account_list[this.state.app + "-" + env]}
          classes={this.state.classes}
          user_name={this.props.user_name}
          app_clickable={this.state.app_clickable}
        />
      );
    } else {
      // If app is clickable, return dummy button to make sure alignment is ok
      return (
        <Grid
          container
          spacing={24}
          direction="row"
          justify="flex-start"
          className={this.state.classes.gridContainer}
        >
          <Grid item xs={2}>
            {this.state.app_clickable && (
              <Button size="small" color="default" disabled variant="contained">
                N/A
              </Button>
            )}
          </Grid>
        </Grid>
      );
    }
  }

  render() {
    return (
      <div>
        <Grid
          container
          spacing={24}
          direction={this.state.grid_direction}
          justify="space-around"
          className={this.state.classes.gridContainer}
        >
          <Grid item xs={2}>
            {this.renderEnv("prd")}
          </Grid>
          <Grid item xs={2}>
            {this.renderEnv("stg")}
          </Grid>
          <Grid item xs={2}>
            {this.renderEnv("dev")}
          </Grid>
          <Grid item xs={2}>
            <Grid
              container
              spacing={24}
              direction="row"
              justify="flex-start"
              className={this.state.classes.gridContainer}
            >
              <Grid item xs={2}>
                <Button
                  size="small"
                  color="default"
                  variant="contained"
                  onClick={() => {
                    history.push({
                      pathname: "/livraison/" + this.state.app
                    });
                  }}
                >
                  Pipeline
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default AppCardList;
