import API from "@aws-amplify/api/lib";

export const getApplicationHeadline = async applicationName =>
  API.get(
    "api-exsi-backend-v2",
    "/applications/" + applicationName + "/headline",
    {}
  );

export const putApplicationHeadline = async (
  applicationName,
  updatedHeadline
) =>
  API.put(
    "api-exsi-backend-v2",
    "/applications/" + applicationName + "/headline",
    {
      body: updatedHeadline
    }
  );
