import React from "react";
import { withSnackbar } from "notistack";
import { CircularLoading, styles } from "../../Styles/ComponentStyles";
import { withStyles } from "@material-ui/core/styles";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import {
  getUserProfile,
  updateUserProfile
} from "../../Services/UserProfilesService";

class DoNotDisturbDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: this.props.userName,
      isDoNotDisturbFetch: false,
      doNotDisturb: false
    };
  }

  componentDidMount() {
    getUserProfile(this.state.userName).then(response => {
      if (response.hasOwnProperty("DoNotDisturb")) {
        this.setState({
          doNotDisturb: response.DoNotDisturb
        });
      }
      this.setState({
        isDoNotDisturbFetch: true
      });
    });
  }

  closeModal = () => {
    this.props.editDoNotDisturbProfileCallback(false);
  };

  updateUserProfile = () => {
    updateUserProfile(this.state.userName, {
      DoNotDisturb: this.state.doNotDisturb
    }).then(() => this.closeModal());
  };

  render() {
    return (
      <Dialog
        open={true}
        onClose={this.closeModal}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Gérer mon accessibilité
        </DialogTitle>
        {this.state.isDoNotDisturbFetch ? (
          <DialogContent>
            Ne pas déranger
            <input
              type="checkbox"
              checked={this.state.doNotDisturb}
              onChange={() =>
                this.setState({
                  doNotDisturb: !this.state.doNotDisturb
                })
              }
            />
          </DialogContent>
        ) : (
          <CircularLoading />
        )}
        <DialogActions>
          <Button onClick={() => this.closeModal()} color="primary">
            Annuler
          </Button>
          <Button onClick={() => this.updateUserProfile()} color="primary">
            Valider
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withSnackbar(withStyles(styles)(DoNotDisturbDialog));
