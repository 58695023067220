import API from "@aws-amplify/api/lib";

export const updateUserProfileFromCognitoPayload = async payload => {
  const userName = payload["cognito:username"];
  const userFirstName = payload["custom:FARO_PRENOM"];
  const userLastName = payload["custom:FARO_NOM"];
  const userEmail = payload["custom:FARO_EMAIL"];

  const body = {
    ...(userFirstName && { userFirstName }),
    ...(userLastName && { userLastName }),
    ...(userEmail && { userEmail })
  };

  if (Object.keys(body).length > 0) {
    const updatedUser = await API.post(
      "api-exsi-backend-v2",
      "/userProfiles/" + userName,
      {
        body
      }
    );
    return {
      UserName: userName,
      ...updatedUser.Attributes
    };
  }
  return { UserName: userName };
};

export const updateUserProfile = async (userName, body) => {
  const apiResult = await API.post(
    "api-exsi-backend-v2",
    "/userProfiles/" + userName,
    { body }
  );
  return { ...apiResult.Attributes };
};

export const getUserProfile = userName =>
  API.get("api-exsi-backend-v2", "/userProfiles/" + userName, {});

export const getUserProfiles = () =>
  API.get("api-exsi-backend-v2", "/userProfiles", {});

export const retrieveUsersWithPerimeter = (users, perimeter) =>
  users
    .filter(
      item =>
        item.hasOwnProperty("Perimeters") && item.Perimeters.includes(perimeter)
    )
    .map(item => ({
      UserName: item["UserName"],
      FirstName: item["FirstName"],
      LastName: item["LastName"]
    }));

export const retrieveAttributeFromCognitoRow = (row, attributeName) => {
  const attribute = row.Attributes.find(
    attribute => attribute.Name === attributeName
  );
  return attribute ? attribute.Value : "";
};
