import React, { useState } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Button, Dialog, DialogContent } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import DialogActions from "@material-ui/core/DialogActions";
import { updateUserProfile } from "../../Services/UserProfilesService";
import { CircularLoading } from "../../Styles/ComponentStyles";
import moment from "moment";
import { addLog } from "../../Services/LogsService";
import { getCurrentUserProfile } from "../../Services/AuthService";

const UsersManagementPerimetersList = ({
  open,
  onClose,
  onValidate,
  perimeters,
  userProfile
}) => {
  const [updatedUserPerimetersList, setUpdatedUserPerimetersList] = useState(
    userProfile.Perimeters ? userProfile.Perimeters : []
  );
  const [isUpdateInProgress, setIsUpdateInProgress] = useState(false);

  const handleChange = event => {
    const selectedPerimeter = event.target.value;
    if (event.target.checked) {
      setUpdatedUserPerimetersList([
        ...updatedUserPerimetersList,
        selectedPerimeter
      ]);
    } else {
      setUpdatedUserPerimetersList(
        updatedUserPerimetersList.filter(
          perimeter => perimeter !== selectedPerimeter
        )
      );
    }
  };

  const handleClose = () => {
    setUpdatedUserPerimetersList(
      userProfile.Perimeters ? userProfile.Perimeters : []
    );
    setIsUpdateInProgress(false);
    onClose();
  };

  const handleValidate = async () => {
    setIsUpdateInProgress(true);
    await updateUserProfile(userProfile.UserName, {
      perimeters: updatedUserPerimetersList
    });
    const currentUser = await getCurrentUserProfile();
    logValidate(currentUser);
    onValidate();
  };

  const logValidate = currentUser => {
    const currentTime = moment().unix();
    const actionDescription =
      updatedUserPerimetersList.length === 0
        ? "Cet utilisateur n'a plus aucune application associée : " +
          userProfile.UserName
        : "Nouvelle liste d'applications pour l'utilisateur " +
          userProfile.UserName +
          " : " +
          updatedUserPerimetersList;
    addLog(currentUser.UserName, currentTime, actionDescription);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle
        style={{
          backgroundColor: "#3f51b5",
          textAlign: "center"
        }}
      >
        <div style={{ color: "white" }}>
          Modifier la liste des périmètres de {userProfile.UserName} -{" "}
          {userProfile.FirstName} {userProfile.LastName}
        </div>
      </DialogTitle>
      {!isUpdateInProgress ? (
        <DialogContent>
          {perimeters.map(perimeter => (
            <Grid item key={perimeter}>
              <label key={perimeter}>
                <input
                  name={perimeter}
                  value={perimeter}
                  type="checkbox"
                  checked={updatedUserPerimetersList.indexOf(perimeter) > -1}
                  onChange={handleChange}
                />
                {perimeter}
              </label>
            </Grid>
          ))}
        </DialogContent>
      ) : (
        <CircularLoading />
      )}
      <DialogActions>
        <Button
          onClick={handleClose}
          variant={"contained"}
          disabled={isUpdateInProgress}
        >
          <div
            style={{
              color: "white"
            }}
          >
            Annuler
          </div>
        </Button>
        <Button
          onClick={handleValidate}
          variant={"contained"}
          style={{
            backgroundColor: "#32CD32"
          }}
          disabled={isUpdateInProgress}
        >
          <div
            style={{
              color: "white"
            }}
          >
            Valider
          </div>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UsersManagementPerimetersList;
