import { API } from "aws-amplify";

export const realizeAction = (
  userIdToken,
  account_id,
  resource_service,
  resource_action,
  resource_id,
  resource_identifier,
  is_body_array,
  callback,
  next_token_name_request,
  next_token_name_response,
  next_token,
  current_response,
  response_identifier,
  region = "eu-west-1",
  body_addendum = null
) => {
  //Simple case: no next_token_name
  if (!next_token_name_request) {
    let body;
    if (resource_identifier !== null) {
      body = {
        [resource_identifier]: is_body_array ? [resource_id] : resource_id
      };
    } else {
      body = {};
    }
    let final_body = "";
    if (body_addendum != null) {
      final_body = Object.assign({}, body, body_addendum);
    } else {
      final_body = body;
    }
    API.post("api-exsi-backend", "/realize", {
      queryStringParameters: {
        boto_service: resource_service,
        boto_method: resource_action,
        account_id: account_id,
        region: region
      },
      body: final_body
    })
      .then(response => {
        callback(response);
      })
      .catch(error => {
        callback(error);
      });
  }
  //next_token_name is set and current_response is null
  if (next_token_name_request && current_response.length === 0) {
    let body;
    if (resource_identifier !== null) {
      body = {
        [resource_identifier]: is_body_array ? [resource_id] : resource_id
      };
    } else {
      body = {};
    }
    API.post("api-exsi-backend", "/realize", {
      queryStringParameters: {
        boto_service: resource_service,
        boto_method: resource_action,
        account_id: account_id,
        region: region
      },
      body: body
    })
      .then(response => {
        realizeAction(
          userIdToken,
          account_id,
          resource_service,
          resource_action,
          resource_id,
          resource_identifier,
          is_body_array,
          callback,
          next_token_name_request,
          next_token_name_response,
          response[next_token_name_response],
          current_response.concat(response[response_identifier]),
          response_identifier,
          region
        );
      })
      .catch(error => {
        callback(error);
      });
  }
  //next_token_name is set and current_response is not null without next_token
  if (
    next_token_name_request &&
    current_response.length !== 0 &&
    next_token === undefined
  ) {
    callback(current_response);
  }

  //next_token_name is set and current_response is not null with next_token
  if (
    next_token_name_request &&
    current_response.length !== 0 &&
    next_token !== undefined
  ) {
    let body;
    if (resource_identifier !== null) {
      body = {
        [resource_identifier]: is_body_array ? [resource_id] : resource_id,
        [next_token_name_request]: next_token
      };
    } else {
      body = {
        [next_token_name_request]: next_token
      };
    }
    API.post("api-exsi-backend", "/realize", {
      queryStringParameters: {
        boto_service: resource_service,
        boto_method: resource_action,
        account_id: account_id,
        region: region
      },
      body: body
    })
      .then(response => {
        if (response[next_token_name_response] !== undefined) {
          realizeAction(
            userIdToken,
            account_id,
            resource_service,
            resource_action,
            resource_id,
            resource_identifier,
            is_body_array,
            callback,
            next_token_name_request,
            next_token_name_response,
            response[next_token_name_response],
            current_response.concat(response[response_identifier]),
            response_identifier,
            region
          );
        } else {
          callback(current_response.concat(response[response_identifier]));
        }
      })
      .catch(error => {
        callback(error);
      });
  }
};
