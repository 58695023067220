import React, { useEffect, useState } from "react";
import Card from "@material-ui/core/Card";
import moment from "moment";
import Button from "@material-ui/core/Button";
import PipelineStageInProgressApproval from "./PipelineStageInProgressApproval";
import {
  findCorrectSourceRevision,
  renderStatus,
  stopPipelineExecution
} from "../../Services/PipelinesService";
import history from "../../history";
import PipelineRevisions from "./PipelineRevisions";

const PipelineStageInProgress = ({
  actionStates,
  lastTwoExecutions,
  stageName,
  pipelineName,
  userName,
  status,
  app,
  accountId
}) => {
  const [isApprovalNeededIndex, setIsApprovalNeededIndex] = useState(-1);
  const [
    isPipelineStageInProgressApprovalOpened,
    setIsPipelineStageInProgressApprovalOpened
  ] = useState(false);
  const closePipelineStageInProgressApproval = () =>
    setIsPipelineStageInProgressApprovalOpened(false);

  useEffect(() => {
    setIsApprovalNeededIndex(
      actionStates.findIndex(
        actionState =>
          actionState.latestExecution && actionState.latestExecution.token
      )
    );
  }, [actionStates]);
  const [openAction, handleOpenAction] = useState(false);

  return isApprovalNeededIndex === -1 ? (
    <div>
      <Card
        style={{
          backgroundColor: renderStatus(status),
          margin: "0.5em",
          padding: "0.5em",
          textAlign: "left",
          color: "white"
        }}
      >
        <div>
          {findCorrectSourceRevision(
            lastTwoExecutions[0].sourceRevisions
          ).revisionId.slice(0, 8)}{" "}
          -{" "}
          {
            findCorrectSourceRevision(lastTwoExecutions[0].sourceRevisions)
              .actionName
          }
          :{" "}
          {
            findCorrectSourceRevision(lastTwoExecutions[0].sourceRevisions)
              .revisionSummary
          }
        </div>
        {lastTwoExecutions[0].sourceRevisions.length > 1
          ? (
              <span>
                <Button
                  size="small"
                  style={{
                    color: "white",
                    padding: 0,
                    minWidth: "auto"
                  }}
                  onClick={() => handleOpenAction(!openAction)}
                >
                  ...
                </Button>
                <PipelineRevisions
                  open={openAction}
                  onClose={() => handleOpenAction(!openAction)}
                  sourceRevisions={lastTwoExecutions[0].sourceRevisions}
                />
              </span>
            )
          : <br />}
        <br />
        <div
          style={{
            fontSize: "small",
            textAlign: "right"
          }}
        >
          Dernière exécution:{" "}
          {moment(
            actionStates[actionStates.length - 1].latestExecution &&
              actionStates[actionStates.length - 1].latestExecution
                .lastStatusChange
              ? actionStates[actionStates.length - 1].latestExecution
                  .lastStatusChange
              : lastTwoExecutions[0].lastUpdateTime
          ).format("ddd Do MMMM YYYY, HH:mm:ss")}
        </div>
      </Card>
      <Button
        onClick={async () => {
          await stopPipelineExecution(
            pipelineName,
            lastTwoExecutions[0].pipelineExecutionId,
            userName,
            accountId
          );
          history.push({
            pathname: "/livraison/" + app
          });
        }}
        color="secondary"
        variant="contained"
      >
        Stopper la livraison en cours
      </Button>
    </div>
  ) : (
    <div>
      {lastTwoExecutions.length === 2 && (
        <Card
          style={{
            backgroundColor: renderStatus(lastTwoExecutions[1].status),
            margin: "0.5em",
            padding: "0.5em",
            textAlign: "left",
            color: "white"
          }}
        >
          <div>
            {findCorrectSourceRevision(
              lastTwoExecutions[1].sourceRevisions
            ).revisionId.slice(0, 8)}{" "}
            -{" "}
            {
              findCorrectSourceRevision(lastTwoExecutions[1].sourceRevisions)
                .actionName
            }
            :{" "}
            {
              findCorrectSourceRevision(lastTwoExecutions[1].sourceRevisions)
                .revisionSummary
            }
          </div>
          <br />
          <br />
          <div
            style={{
              fontSize: "small",
              textAlign: "right"
            }}
          >
            Dernière exécution:{" "}
            {moment(
              actionStates[actionStates.length - 1].latestExecution &&
                actionStates[actionStates.length - 1].latestExecution
                  .lastStatusChange
                ? actionStates[actionStates.length - 1].latestExecution
                    .lastStatusChange
                : lastTwoExecutions[1].lastUpdateTime
            ).format("ddd Do MMMM YYYY, HH:mm:ss")}
          </div>
        </Card>
      )}
      <Card
        style={{
          backgroundColor: renderStatus(status),
          margin: "0.5em",
          padding: "0.5em",
          textAlign: "left",
          color: "white"
        }}
      >
        <div>
          {findCorrectSourceRevision(
            lastTwoExecutions[0].sourceRevisions
          ).revisionId.slice(0, 8)}{" "}
          -{" "}
          {
            findCorrectSourceRevision(lastTwoExecutions[0].sourceRevisions)
              .actionName
          }
          :{" "}
          {
            findCorrectSourceRevision(lastTwoExecutions[0].sourceRevisions)
              .revisionSummary
          }
        </div>
        <br />
        <br />
        <div
          style={{
            fontSize: "small",
            textAlign: "right"
          }}
        >
          Dernière exécution:{" "}
          {moment(
            actionStates[actionStates.length - 1].latestExecution &&
              actionStates[actionStates.length - 1].latestExecution
                .lastStatusChange
              ? actionStates[actionStates.length - 1].latestExecution
                  .lastStatusChange
              : lastTwoExecutions[0].lastUpdateTime
          ).format("ddd Do MMMM YYYY, HH:mm:ss")}
        </div>
      </Card>
      <Button
        color="primary"
        variant="contained"
        onClick={() => setIsPipelineStageInProgressApprovalOpened(true)}
      >
        Livrer la version{" "}
        {findCorrectSourceRevision(
          lastTwoExecutions[0].sourceRevisions
        ).revisionId.slice(0, 8)}
      </Button>
      <PipelineStageInProgressApproval
        open={isPipelineStageInProgressApprovalOpened}
        onClose={closePipelineStageInProgressApproval}
        pipelineName={pipelineName}
        stageName={stageName}
        lastTwoExecutions={lastTwoExecutions}
        actionName={actionStates[isApprovalNeededIndex].actionName}
        token={actionStates[isApprovalNeededIndex].latestExecution.token}
        userName={userName}
        app={app}
        accountId={accountId}
      />
    </div>
  );
};

export default PipelineStageInProgress;
