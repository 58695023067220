import React, { useEffect, useState } from "react";
import {
  filterPipelines,
  getAllPipelines,
  reversePipelineNamesAppMap
} from "../../Services/PipelinesService";
import { CircularLoading } from "../../Styles/ComponentStyles";
import PipelineGeneral from "./PipelineGeneral";
import { groupAccountsByApp } from "../../Services/AccountService";

const PipelinesDashboard = ({ app, accountList }) => {
  const [appPipelineNames, setAppPipelineNames] = useState(undefined);
  const [appEnvIdList] = useState(
    Object.values(groupAccountsByApp(accountList)[app])
  );
  const [pipelineNameAccountIdMap, setPipelineNameAccountIdMap] = useState(
    undefined
  );

  useEffect(() => {
    const fetchAppPipelineNames = async () => {
      Promise.all([
        getAllPipelines(),
        ...appEnvIdList.map(async accountId => ({
          [accountId]: await getAllPipelines(accountId)
        }))
      ]).then(responses => {
        const [
          pipelineNamesSharPrd,
          ...accountIdPipelineNamesAppMapList
        ] = responses;
        const reversedPipelineNamesAppMap = reversePipelineNamesAppMap(
          accountIdPipelineNamesAppMapList
        );
        setPipelineNameAccountIdMap(reversedPipelineNamesAppMap);
        setAppPipelineNames(
          filterPipelines(
            pipelineNamesSharPrd.concat(
              Object.keys(reversedPipelineNamesAppMap)
            ),
            accountList,
            app
          )
        );
      });
    };

    fetchAppPipelineNames();
  }, [app, accountList, appEnvIdList]);

  return !appPipelineNames || !pipelineNameAccountIdMap ? (
    <CircularLoading />
  ) : appPipelineNames.length === 0 ? (
    <h1>Aucun pipeline disponible</h1>
  ) : (
    appPipelineNames.map(appPipelineName => {
      return (
        <PipelineGeneral
          key={appPipelineName}
          pipelineName={appPipelineName}
          app={app}
          accountId={pipelineNameAccountIdMap[appPipelineName]}
        />
      );
    })
  );
};

export default PipelinesDashboard;
