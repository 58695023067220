import React from "react";

import { withStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Modal from "@material-ui/core/Modal";
import Slide from "@material-ui/core/Slide";
import Cached from "@material-ui/icons/Cached";
import Grid from "@material-ui/core/Grid";
import { withSnackbar } from "notistack";
import moment from "moment";
import Moment from "moment";
import PropTypes from "prop-types";

import AlarmChannelsTable from "../Tables/AlarmChannelsTable";
import AlarmHistoryTable from "../Tables/AlarmHistoryTable";
import AddChannelDialog from "../Dialogs/AddChannelDialog";
import Circle from "../Geometric/Circle";
import Tooltip from "@material-ui/core/Tooltip";
import {
  circleColor,
  CircularLoading,
  styles
} from "../../Styles/ComponentStyles";
import { realizeAction } from "../../Helpers/realize_action";
import {
  deleteChannel,
  retrieveChannelsWithParameters
} from "../../Services/ChannelsService";
import { addLog } from "../../Services/LogsService";
import {
  getAccountAlarmLogs,
  getAccountState
} from "../../Services/AccountService";

//Set the fr language for moment
moment.locale("fr");

class AlarmDashboardTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      alarms: undefined,
      alarmsWithHistory: undefined,
      listAlarmInProgress: false,
      loading: false,
      surveillance: undefined,
      getActualSurveillanceInProgress: false,
      modalSurveillanceOpen: false,
      modalAddChannelOpen: false,
      modalHistoryOpen: false,
      current_alarm: undefined,
      form_account_id_error: false,
      seconds: 0,
      applicationState: undefined
    };
    this.listAlarms = this.listAlarms.bind(this);
    this.handleSurveillanceClose = this.handleSurveillanceClose.bind(this);
    this.delete_channel_callback = this.delete_channel_callback.bind(this);
    this.handleHistoryClose = this.handleHistoryClose.bind(this);
    this.handleAddChannelClose = this.handleAddChannelClose.bind(this);
    this.getActualSurveillance = this.getActualSurveillance.bind(this);
    this.getAlarmsListCallback = this.getAlarmsListCallback.bind(this);
  }

  componentDidMount() {
    this.interval = setInterval(() => this.tick(), 1000);
    this.componentWillReceiveProps(this.props);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  tick() {
    this.setState(prevState => ({
      seconds: prevState.seconds + 1
    }));
  }

  getModalStyle() {
    return {
      margin: "auto"
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      ...nextProps
    });

    if (nextProps.account_id !== undefined) {
      if (
        this.state.alarms === undefined ||
        this.state.account_id !== nextProps.account_id
      ) {
        this.setState({
          loading: true
        });
        getAccountState(this.state.account_id).then(result => {
          this.setState({
            applicationState: result
          });
        });
        this.listAlarms(nextProps.account_id);
        this.getActualSurveillance(nextProps.account_id);
      }
    }
  }

  getAlarmsListCallback(response) {
    debugger;
    this.setState({
      alarms: response["MetricAlarms"],
      listAlarmInProgress: false,
      seconds: 0
    });
    this.getHistory(this.state.account_id);
  }

  listAlarms(account_id) {
    if (this.state.listAlarmInProgress || !account_id) {
      return;
    }
    this.setState(
      {
        listAlarmInProgress: true
      },
      () => {
        realizeAction(
          this.state.userIdToken,
          account_id,
          "cloudwatch",
          "describe_alarms",
          null,
          null,
          null,
          this.getAlarmsListCallback,
          null,
          null,
          null,
          null,
          null,
          "eu-west-1",
          {
            MaxRecords: 100
          }
        );
      }
    );
  }

  isAlarmSetUp = (alarm_action, ok_action) =>
    [
      "arn:aws:sns:eu-west-1:182726513909:snt-exsi-notifications",
      "arn:aws:sns:eu-west-1:815704857845:snt-exsi-notifications",
      "arn:aws:sns:eu-west-1:726054927466:snt-exsi-notifications"
    ]
      .map(
        sns_arn => alarm_action.includes(sns_arn) && ok_action.includes(sns_arn)
      )
      .find(value => !value) === undefined;

  renderCircle(status, alarm_action, ok_action, classes) {
    if (!this.isAlarmSetUp(alarm_action, ok_action)) {
      return (
        <Grid container spacing={8} className={classes.gridContainer}>
          <Grid item xs={3}>
            <Circle bgColor={circleColor.yellow} circleSize={20} />
          </Grid>
          <Grid item xs={6}>
            <Typography>
              (L'alarme n'est pas configurée correctement)
            </Typography>
          </Grid>
        </Grid>
      );
    }

    switch (status) {
      case "ALARM":
        return <Circle bgColor={circleColor.red} circleSize={20} />;

      case "OK":
        return <Circle bgColor={circleColor.green} circleSize={20} />;

      default:
        return <Circle bgColor={circleColor.gray} circleSize={20} />;
    }
  }

  handleAddChannelClose() {
    this.setState({
      modalAddChannelOpen: false
    });
  }

  handleSurveillanceClickOpen(alarm_name) {
    this.setState({
      modalSurveillanceOpen: true,
      current_alarm: alarm_name
    });
  }

  handleSurveillanceClose() {
    this.setState({
      modalSurveillanceOpen: false
    });
  }

  handleHistoryClickOpen(alarm_name, history) {
    this.setState({
      modalHistoryOpen: true,
      current_alarm: alarm_name,
      current_history: history
    });
  }

  handleHistoryClose() {
    this.setState({
      modalHistoryOpen: false,
      current_history: undefined
    });
  }

  getActualSurveillance(accountId) {
    if (this.state.getActualSurveillanceInProgress || !accountId) {
      return;
    }
    this.setState(
      {
        alarms: undefined,
        getActualSurveillanceInProgress: true
      },
      () => {
        retrieveChannelsWithParameters({ accountId }).then(response => {
          this.setState({
            surveillance: response,
            getActualSurveillanceInProgress: false
          });
        });
      }
    );
  }

  getHistory(account_id) {
    if (this.state.getHistoryInProgress || !account_id) {
      return;
    }
    this.setState(
      {
        getHistoryInProgress: true
      },
      () => {
        getAccountAlarmLogs(account_id).then(response => {
          const alarmsWithHistory = this.state.alarms
            .map(alarm => ({
              alarm,
              history: response
                .filter(
                  item =>
                    item["AlarmName"] && item["AlarmName"] === alarm.AlarmName
                )
                .sort((a, b) => {
                  const hasAGoodTimeStamp = a["ts"] && a["ts"];
                  const hasBGoodTimeStamp = b["ts"] && b["ts"];
                  if (!hasAGoodTimeStamp && !hasBGoodTimeStamp) {
                    return 0;
                  }

                  if (hasAGoodTimeStamp && !hasBGoodTimeStamp) {
                    return -1;
                  }

                  if (!hasAGoodTimeStamp && hasBGoodTimeStamp) {
                    return 1;
                  }
                  return a["ts"] > b["ts"] ? -1 : 1;
                })
            }))
            .sort((a, b) => {
              const hasAHistory = a.history.length > 0;
              const hasBHistory = b.history.length > 0;

              if (!hasAHistory && !hasBHistory) {
                return 0;
              }

              if (hasAHistory && !hasBHistory) {
                return -1;
              }

              if (!hasAHistory && hasBHistory) {
                return 1;
              }

              return a.history[0]["ts"] > b.history[0]["ts"] ? -1 : 1;
            });
          this.setState({
            alarmsWithHistory: alarmsWithHistory,
            getHistoryInProgress: false,
            loading: false
          });
        });
      }
    );
  }

  delete_channel_callback(account_id, alarm_name, uuid) {
    deleteChannel(account_id, uuid).then(() => {
      const action_description =
        "Suppression d'un channel sur le compte : " +
        account_id +
        " avec le nom " +
        alarm_name +
        " et UUID:" +
        uuid;
      const current_time = moment().unix();
      addLog(this.props.user_name, current_time, action_description);
      this.listAlarms(this.state.account_id);
      this.getActualSurveillance(this.state.account_id);
      this.handleSurveillanceClose();
      this.props.enqueueSnackbar(action_description, {
        variant: "error",
        autoHideDuration: 5000
      });
    });
  }

  renderSurveillance(alarm_name) {
    let channel_counter = 0;
    if (this.state.surveillance) {
      if ("Items" in this.state.surveillance) {
        for (let i = 0; i < this.state.surveillance["Items"].length; i++) {
          let regex = this.state.surveillance.Items[i]["AlarmName"].replace(
            "*",
            ".*"
          );
          if (alarm_name.match(regex)) {
            channel_counter++;
          }
        }
      }
    }
    if (channel_counter === 0) {
      return "Aucun canal";
    } else if (channel_counter === 1) {
      return (
        <Button
          onClick={() => this.handleSurveillanceClickOpen(alarm_name)}
          color="primary"
          key={alarm_name}
        >
          {channel_counter} canal
        </Button>
      );
    } else {
      return (
        <Button
          onClick={() => this.handleSurveillanceClickOpen(alarm_name)}
          color="primary"
          key={alarm_name}
        >
          {channel_counter} canaux
        </Button>
      );
    }
  }

  renderHistory(alarm_name, history) {
    return history.length > 0 ? (
      <Button
        onClick={() => this.handleHistoryClickOpen(alarm_name, history)}
        color="primary"
        key={alarm_name}
      >
        {Moment(new Date(history[0].ts * 1000)).format(
          "ddd Do MMMM YYYY, HH:mm:ss"
        )}{" "}
        ({history.length} évenements)
      </Button>
    ) : (
      <Button disabled color="primary" key={alarm_name}>
        Aucun évenement
      </Button>
    );
  }

  renderAddChannelDialog() {
    return (
      <AddChannelDialog
        account_id={this.state.account_id}
        account_list={this.props.account_list}
        user_name={this.props.user_name}
        userIdToken={this.state.userIdToken}
        visible={this.state.modalAddChannelOpen}
        onClose={this.handleAddChannelClose}
        env={this.state.env}
        app={this.state.app}
      />
    );
  }

  render() {
    const { classes } = this.props;
    if (!this.state.applicationState) {
      return (
        <div className="loading" style={{ textAlign: "center" }}>
          <CircularLoading />
        </div>
      );
    } else if (this.state.applicationState === "down") {
      return (
        <div>
          <Circle bgColor={circleColor.black} circleSize={40} />
          <Typography variant="h6" id="modal-title">
            L'environnement a été éteint, vous pouvez le rallumer avec l'action
            laf-env-controller-start.
          </Typography>
        </div>
      );
    } else if (
      this.state.loading === false &&
      this.state.alarms &&
      this.state.alarms.length > 0 &&
      this.state.alarmsWithHistory
    ) {
      return (
        <div>
          <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={this.state.modalSurveillanceOpen}
            onClose={this.handleSurveillanceClose}
            style={{ alignItems: "center", justifyContent: "center" }}
          >
            <Slide
              direction="up"
              in={this.state.modalSurveillanceOpen}
              mountOnEnter
              unmountOnExit
            >
              <div
                style={this.getModalStyle()}
                className={classes.paperModalSurveillance}
              >
                <Typography variant="h6" id="modal-title">
                  Les canaux de l'alarme
                </Typography>
                <AlarmChannelsTable
                  surveillance={this.state.surveillance}
                  alarm_name={this.state.current_alarm}
                  delete_channel_callback={this.delete_channel_callback}
                  match_alarm={true}
                ></AlarmChannelsTable>
              </div>
            </Slide>
          </Modal>

          <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={this.state.modalHistoryOpen}
            onClose={this.handleHistoryClose}
            style={{ alignItems: "center", justifyContent: "center" }}
          >
            <Slide
              direction="up"
              in={this.state.modalHistoryOpen}
              mountOnEnter
              unmountOnExit
            >
              <div
                style={this.getModalStyle()}
                className={classes.paperModalSurveillance}
              >
                <Typography variant="h6" id="modal-title">
                  Historique des changements d'état
                </Typography>
                <AlarmHistoryTable
                  history={this.state.current_history}
                  alarm_name={this.state.current_alarm}
                ></AlarmHistoryTable>
              </div>
            </Slide>
          </Modal>

          {this.renderAddChannelDialog()}

          <Paper className={classes.root}>
            <Button
              size="small"
              color="primary"
              onClick={() => {
                this.setState(
                  {
                    seconds: 0
                  },
                  () => {
                    this.setState({
                      loading: true
                    });
                    this.listAlarms(this.state.account_id);
                    this.getActualSurveillance(this.state.account_id);
                  }
                );
              }}
            >
              <Cached /> Rafraîchir ({this.state.seconds} s)
            </Button>
            <Paper className={classes.paperAlarmList}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>Statut</TableCell>
                    <TableCell>Surveillance</TableCell>
                    <TableCell>Alarme</TableCell>
                    <TableCell>Dernier changement d'état</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.alarmsWithHistory.map(row => (
                    <TableRow key={row.alarm.AlarmArn}>
                      <TableCell align="left">
                        {this.renderCircle(
                          row.alarm.StateValue,
                          row.alarm.AlarmActions,
                          row.alarm.OKActions,
                          classes
                        )}
                      </TableCell>
                      <TableCell align="left">
                        {this.renderSurveillance(row.alarm.AlarmName)}
                      </TableCell>
                      <TableCell align="left">
                        <Tooltip
                          title={
                            row.alarm.AlarmDescription ||
                            "Pas de description disponible."
                          }
                          classes={{ tooltip: classes.lightTooltip }}
                        >
                          <Typography>{row.alarm.AlarmName}</Typography>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="left">
                        {this.renderHistory(row.alarm.AlarmName, row.history)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          </Paper>
        </div>
      );
    } else if (this.state.loading === true) {
      return (
        <div className="loading" style={{ textAlign: "center" }}>
          <CircularLoading />
        </div>
      );
    } else {
      return "";
    }
  }
}

AlarmDashboardTable.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withSnackbar(withStyles(styles)(AlarmDashboardTable));
