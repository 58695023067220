import React from "react";
import { groupAccountsByApp } from "../../Services/AccountService";
import BillingApp from "./BillingApp";
import Card from "@material-ui/core/Card";

const BillingGeneral = ({ accountList }) => (
  <div>
    <div style={{ fontSize: "x-large", marginTop: "1em" }}>
      <b>FACTURATION</b>
    </div>
    {Object.entries(groupAccountsByApp(accountList)).map(
      ([appName, environments]) => (
        <Card
          style={{
            padding: "1em",
            margin: "3em",
            marginLeft: "15em",
            marginRight: "15em"
          }}
          key={appName}
        >
          <BillingApp
            appName={appName}
            environments={environments}
          ></BillingApp>
        </Card>
      )
    )}
  </div>
);

export default BillingGeneral;
