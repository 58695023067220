import React, { useEffect, useState } from "react";
import { retrieveAdmins } from "../../Services/AdminService";
import { CircularLoading } from "../../Styles/ComponentStyles";
import PerimetersManagementDashboard from "./PerimetersManagementDashboard";
import Card from "@material-ui/core/Card";

const PerimetersManagementGeneral = ({ userName, accountList }) => {
  const [isUserAdmin, setIsUserAdmin] = useState(undefined);

  useEffect(() => {
    const retrievePerimetersAdmins = async () => {
      const userAdmins = await retrieveAdmins("perimeters");
      setIsUserAdmin(userAdmins.indexOf(userName) > -1);
    };
    retrievePerimetersAdmins();
  }, [userName]);
  return isUserAdmin !== undefined ? (
    isUserAdmin ? (
      <div>
        <div style={{ fontSize: "x-large", marginTop: "1em" }}>
          <b>GESTION DES PÉRIMÈTRES</b>
        </div>
        <Card
          style={{
            padding: "1em",
            margin: "3em",
            marginLeft: "10em",
            marginRight: "10em"
          }}
        >
          <PerimetersManagementDashboard accountList={accountList} />
        </Card>
      </div>
    ) : (
      <div>
        {" "}
        Vous n'avez pas le droit d'accéder à cette page. Contacter un
        administrateur
      </div>
    )
  ) : (
    <CircularLoading />
  );
};

export default PerimetersManagementGeneral;
