import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import { styles } from "../../Styles/ComponentStyles";

class NoApps extends React.Component {
  constructor(props) {
    super();
    const { classes } = props;
    this.state = {
      classes: classes,
      ...props
    };
  }

  render() {
    return (
      <div className={this.state.classes.rootNoApps}>
        <Paper className={this.state.classes.rootNoApps} elevation={1}>
          <Typography variant="h5" component="h3">
            Aucune application trouvée. Contacter un administrateur
          </Typography>
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles)(NoApps);
