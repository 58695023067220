import API from "@aws-amplify/api/lib";

export const retrieveAllPerimeters = async () =>
  API.get("api-exsi-backend-v2", "/perimeters", {});

export const addPerimeter = async (
  perimeterName,
  applicationList,
  dutyPhoneNumber,
  email
) =>
  API.post("api-exsi-backend-v2", "/perimeters/" + perimeterName, {
    body: {
      applicationList,
      dutyPhoneNumber,
      ...(email && { email })
    }
  });

export const deletePerimeter = async perimeterName =>
  API.del("api-exsi-backend-v2", "/perimeters/" + perimeterName, {});

export const retrievePerimeterHuntGroup = async perimeterName =>
  API.get("api-exsi-backend-v2", "/huntGroup", {
    queryStringParameters: {
      perimeter_name: perimeterName
    }
  });

export const updatePerimeterHuntGroupPriority = async (
  perimeterName,
  updatedHuntGroup
) => {
  if (!updatedHuntGroup.length) {
    return;
  }
  const updatedHuntGroupAndPriority = updatedHuntGroup.map((user, index) => ({
    UserName: user.UserName,
    Priority: index + 1
  }));
  return API.post("api-exsi-backend-v2", "/huntGroup", {
    queryStringParameters: {
      perimeter_name: perimeterName
    },
    body: updatedHuntGroupAndPriority
  });
};
