import React from "react";
import { retrievePerimeterHuntGroup } from "../../Services/PerimetersService";
import { CircularLoading } from "../../Styles/ComponentStyles";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Styles/ScreenStyles";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { getItemStyle, getListStyle } from "../../Styles/DragDropStyles";
import { reorderList } from "../../Helpers/ListHelper";

class HuntGroupVerticalList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      perimeterName: this.props.perimeterName,
      huntGroupList: [],
      isHuntGroupListFetch: false
    };
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  componentDidMount() {
    retrievePerimeterHuntGroup(this.state.perimeterName).then(huntGroupList => {
      this.setState({
        huntGroupList,
        isHuntGroupListFetch: true
      });
    });
  }

  onDragEnd(result) {
    if (!result.destination) {
      return;
    }
    const huntGroupList = reorderList(
      this.state.huntGroupList,
      result.source.index,
      result.destination.index
    );

    this.setState({
      huntGroupList
    });
    this.props.dialogHuntListCallback(huntGroupList);
  }

  render() {
    return this.state.isHuntGroupListFetch ? (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              <p>
                <b>Priorité Haute</b>
              </p>
              {this.state.huntGroupList.map((user, index) => (
                <Draggable
                  key={user.UserName}
                  draggableId={user.UserName}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      {user.UserName} -{" "}
                      {user.FirstName !== "ZZZ" ? user.FirstName : ""}{" "}
                      {user.LastName !== "ZZZ" ? user.LastName : ""}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
              <p>
                <small>Priorité Basse</small>
              </p>
            </div>
          )}
        </Droppable>
      </DragDropContext>
    ) : (
      <CircularLoading> </CircularLoading>
    );
  }
}

export default withStyles(styles)(HuntGroupVerticalList);
