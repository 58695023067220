import React from "react";
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles
} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { CircularLoading, styles } from "../../Styles/ComponentStyles";
import Grid from "@material-ui/core/Grid";

import AppCardList from "../Cards/AppCardList";
import green from "@material-ui/core/colors/green";

const TotalChipTheme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: green
  }
});

class DashboardExpansionPanel extends React.Component {
  constructor(props) {
    super(props);
    let app_list = [];
    let app_status = {};
    let app_spent = {};
    for (let key in props.account_list) {
      let app_name = key.split("-")[0];
      if (!app_list.includes(app_name)) {
        app_list.push(app_name);
        app_status[app_name] = "GATHERING";
        app_spent[app_name] = "GATHERING";
      }
    }
    this.state = {
      app_list: app_list,
      account_list: props.account_list,
      classes: props.classes,
      app_status: app_status,
      app_spent: app_spent,
      userIdToken: this.props.userIdToken,
      grid_direction: props.grid_direction,
      app_clickable: props.app_clickable
    };
  }

  render() {
    const { classes } = this.props;
    if (this.state.app_list.length < 1) {
      return (
        <div
          className="loading"
          style={{ textAlign: "center", paddingTop: "20px" }}
        >
          <CircularLoading />
        </div>
      );
    } else {
      return (
        <div
          style={{ height: "90vh" }}
          className={this.state.classes.rootPanel}
        >
          {this.state.app_list.map(row => {
            return (
              <Card style={{ marginBottom: 20 }} key={row}>
                <CardContent>
                  <Grid
                    container
                    spacing={0}
                    justify="center"
                    alignItems="center"
                    className={classes.gridContainer}
                    key={row}
                  >
                    <MuiThemeProvider theme={TotalChipTheme}>
                      <Grid item xs={2}>
                        <Typography variant="h5">{row}</Typography>
                      </Grid>
                    </MuiThemeProvider>
                    <Grid item xs={8}>
                      <Grid container spacing={8} justify="center">
                        <AppCardList
                          app={row}
                          account_list={this.state.account_list}
                          user_name={this.props.user_name}
                          userIdToken={this.state.userIdToken}
                          classes={this.state.classes}
                          grid_direction={this.state.grid_direction}
                          app_clickable={this.state.app_clickable}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            );
          })}
        </div>
      );
    }
  }
}

export default withStyles(styles)(DashboardExpansionPanel);
