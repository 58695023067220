import React, { memo, useState, useEffect } from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { Button } from "@material-ui/core";
import ViewList from "@material-ui/icons/ViewList";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import UsersManagementPerimetersList from "./UsersManagementPerimetersList";
import UsersManagementDelete from "./UsersManagementDelete";

const useMediaQuery = (query) => {
  const mediaMatch = window.matchMedia(query);
  const [matches, setMatches] = useState(mediaMatch.matches);

  useEffect(() => {
    const handler = e => setMatches(e.matches);
    mediaMatch.addListener(handler);
    return () => mediaMatch.removeListener(handler);
  });
  return matches;
};

const styles = {
  container: isRowBased => ({
    display: isRowBased ? 'table-cell' : 'none'
  })
};

const UsersManagementRow = memo(
  ({ userProfile, perimeters, userProfileUpdateCallBack }) => {
    const [isPerimetersListOpened, setIsPerimetersListOpened] = useState(false);
    const [isUserDeleteOpened, setIsUserDeleteOpened] = useState(false);

    const closePerimetersList = () => setIsPerimetersListOpened(false);
    const closeUserDelete = () => setIsUserDeleteOpened(false);

    const validatePerimetersList = () => {
      setIsPerimetersListOpened(false);
      userProfileUpdateCallBack();
    };
    const validateDeleteUser = () => {
      setIsUserDeleteOpened(false);
      userProfileUpdateCallBack();
    };

    const isRowBased = useMediaQuery('(min-width: 500px)');

    return (
      <TableRow key={userProfile.UserName} style={{ fontSize: "small", padding: '0' }}>
        <TableCell align="center" style={{ fontSize: "small", padding: '0' }}>
          {userProfile.UserName}
        </TableCell>
        <TableCell align="center" style={styles.container(isRowBased)}>
          {userProfile.Email}
        </TableCell>
        <TableCell align="center" style={styles.container(isRowBased)}>
          {userProfile.FirstName}
        </TableCell>
        <TableCell align="center" style={styles.container(isRowBased)}>
          {userProfile.LastName}
        </TableCell>
        <TableCell align="center" style={{ fontSize: "small", padding: '0' }}>
          <Button onClick={() => setIsPerimetersListOpened(true)}>
            <ViewList color="primary" />
          </Button>
          <UsersManagementPerimetersList
            open={isPerimetersListOpened}
            onClose={closePerimetersList}
            onValidate={validatePerimetersList}
            perimeters={perimeters}
            userProfile={userProfile}
          />
        </TableCell>
        <TableCell align="center" style={{ fontSize: "small" , padding: '0' }}>
          <Button onClick={() => setIsUserDeleteOpened(true)}>
            <DeleteForeverOutlinedIcon color="primary" />
          </Button>
          <UsersManagementDelete
            open={isUserDeleteOpened}
            onClose={closeUserDelete}
            onValidate={validateDeleteUser}
            userName={userProfile.UserName}
          />
        </TableCell>
      </TableRow>
    );
  }
);

export default UsersManagementRow;
