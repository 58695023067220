import React, { Component } from "react";
import "./App.css";
import Amplify, { Auth, Hub } from "aws-amplify";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import moment from "moment";
import "moment/locale/fr";
import OAuthButton from "./OAuthButton";

import PrimarySearchAppBar from "./Components/Searches/PrimarySearchAppBar";
import HighLevelDashboard from "./Screens/HighLevelDashboard";
import LowLevelDashboard from "./Screens/LowLevelDashboard";
import Logs from "./Screens/Logs";
import Astreintes from "./Screens/Astreintes";
import ProfilUtilisateur from "./Screens/ProfilUtilisateur";
import NoApps from "./Components/Warnings/NoApps";
import history from "./history";
import Config from "./config.js";
import { updateUserProfileFromCognitoPayload } from "./Services/UserProfilesService";
import HuntGroupsManagement from "./Screens/HuntGroupsManagement";
import BrowserError from "./Components/Errors/BrowserError";
import { CircularLoading } from "./Styles/ComponentStyles";
import PipelinesDashboard from "./Components/Pipelines/PipelinesDashboard";
import PipelineStatesDashboard from "./Components/Pipelines/PipelineStatesDashboard";
import UsersManagementGeneral from "./Components/UsersManagement/UsersManagementGeneral";
import PerimetersManagementGeneral from "./Components/PerimetersManagement/PerimetersManagementGeneral";
import BillingGeneral from "./Components/Billing/BillingGeneral";
import { getAllAccounts } from "./Services/AccountService";
import { retrieveUserApplications } from "./Services/UsersService";

window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

//Set the fr language for moment
moment.locale("fr");

let auth_config = {};
if ("OAUTH_REDIRECT" in Config.cognito) {
  auth_config = {
    region: "eu-west-1",
    userPoolId: Config.cognito.USER_POOL_ID,
    userPoolWebClientId: Config.cognito.USER_POOL_WEB_CLIENT_ID,
    oauth: {
      domain: Config.cognito.OAUTH_DOMAIN,
      scope: ["openid"],
      redirectSignIn: Config.cognito.OAUTH_REDIRECT,
      redirectSignOut: Config.cognito.OAUTH_REDIRECT,
      responseType: "code"
    }
  };
} else {
  auth_config = {
    identityPoolId: Config.cognito.IDENTITY_POOL_ID,
    region: Config.cognito.REGION,
    userPoolId: Config.cognito.USER_POOL_ID,
    userPoolWebClientId: Config.cognito.WEB_CLIENT_ID
  };
}

Amplify.configure({
  API: {
    endpoints: [
      {
        name: "api-exsi-backend",
        endpoint: Config.apiGateway.URL,
        region: Config.apiGateway.REGION,
        custom_header: async () => {
          return {
            Authorization: (await Auth.currentSession()).idToken.jwtToken
          };
        },
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token",
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
          "Content-Type": "application/json",
          Accept: "application/json"
        }
      },
      {
        name: "api-exsi-backend-v2",
        endpoint: Config.apiGatewayV2.URL,
        region: Config.apiGatewayV2.REGION,
        custom_header: async () => {
          return {
            Authorization: (await Auth.currentSession()).idToken.jwtToken
          };
        },
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token",
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
          "Content-Type": "application/json",
          Accept: "application/json"
        }
      }
    ]
  },
  Auth: auth_config
});

class App extends Component {
  constructor(props) {
    super();
    this.state = {
      ...props,
      account_list: {},
      user_app_names: [],
      list_instances: [],
      selected_application: null,
      environment: null,
      currentPage: "mes-applications",
      userName: "",
      currentScreen: "",
      cognitoUserRole: "",
      userAuthTime: "",
      userAccessToken: "",
      userIdToken: "",
      noApps: false,
      authState: "loading",
      authData: null,
      authError: null,
      userFirstName: "",
      userLastName: "",
      userEmail: "",
      isAccountListFetched: false
    };

    // this.setUserInfo();
    this.dashboardGlobale = this.dashboardGlobale.bind(this);
    this.setAccountList = this.setAccountList.bind(this);
    this.setUserInfo = this.setUserInfo.bind(this);
    this.renderSwitch = this.renderSwitch.bind(this);
    this.authCallback = this.authCallback.bind(this);
    // let the Hub module listen on Auth events
    Hub.listen("auth", this.authCallback);
  }

  authCallback(data) {
    if (data !== undefined) {
      switch (data.payload.event) {
        case "signIn":
          this.setUserInfo();
          break;
        case "signIn_failure":
          this.setState({ authState: "signIn" });
          this.setState({ authData: null });
          this.setState({ authError: data.payload.data });
          break;
        default:
          break;
      }
    }
  }

  componentDidMount() {
    // check the current user when the App component is loaded
    Auth.currentAuthenticatedUser()
      .then(async user => {
        this.setUserInfo();
      })
      .catch(e => {
        this.setState({ authState: "signIn" });
      });
  }

  handleEnvironmentChange = (event, env_props) => {
    this.setState(state => ({
      environment: event
    }));
  };

  handleApplicationChange = (event, app_props) => {
    if (event.length !== 0) {
      this.setState(state => ({
        selected_application: event,
        environment: null
      }));
    } else {
      this.setState(state => ({
        selected_application: null,
        environment: null
      }));
    }
  };

  handleLeftMenuClick = event => {
    this.setState(state => ({
      currentPage: event
    }));
  };

  setAccountList() {
    getAllAccounts().then(res => {
      this.setState({
        account_list_full: res
      });
      retrieveUserApplications(this.state.userName).then(app_names => {
        if (!app_names.length) {
          this.setState(state => ({
            noApps: true
          }));
          history.push({
            pathname: "/alerte-apps"
          });
          return null;
        }
        this.setState({
          user_app_names: app_names
        });

        res.map(row => {
          if (
            row["ACCOUNT_NAME"].length === 8 &&
            row["ACCOUNT_NAME"].includes("-")
          ) {
            if (app_names.includes(row["ACCOUNT_NAME"])) {
              this.setState({
                account_list: Object.assign(
                  { [row["ACCOUNT_NAME"]]: row["ACCOUNT_ID"] },
                  this.state.account_list
                )
              });
            }
          }
          return null;
        });
        this.setState({
          isAccountListFetched: true
        });
      });
    });
  }

  setUserInfo() {
    return Amplify.Auth.currentSession()
      .then(async data => {
        updateUserProfileFromCognitoPayload(data.getIdToken().payload)
          .then(userUpdated => {
            const { UserName, FirstName, LastName, Email } = userUpdated;
            this.setState({
              userName: UserName,
              currentScreen: "Admin",
              cognitoUserRole: data.idToken.payload["cognito:roles"],
              userAuthTime: moment().unix(),
              userAccessToken: data.accessToken.jwtToken,
              userIdToken: data.idToken.jwtToken,
              authState: "signedIn",
              userFirstName: FirstName,
              userLastName: LastName,
              userEmail: Email
            });
          })
          .then(() => this.setAccountList());
        if (
          JSON.parse(sessionStorage.getItem("authenticated")) === null ||
          JSON.parse(sessionStorage.getItem("authenticated")) === false
        ) {
          sessionStorage.setItem("authenticated", true);
        }
      })
      .catch(err => console.log(err));
  }

  primarySearchAppBar() {
    return (
      <PrimarySearchAppBar
        currentPage={this.state.currentPage}
        user_name={this.state.userName}
        userIdToken={this.state.userIdToken}
        selected_application={this.state.selected_application}
        environment={this.state.environment}
        handleApplicationChange={this.handleApplicationChange}
        userFirstName={this.state.userFirstName}
        userLastName={this.state.userLastName}
      />
    );
  }

  dashboardGlobale() {
    return (
      <Route
        path="/mes-applications"
        render={props => (
          <HighLevelDashboard
            {...props}
            account_list={this.state.account_list}
            user_name={this.state.userName}
            userIdToken={this.state.userIdToken}
            noApps={this.state.noApps}
          />
        )}
      />
    );
  }

  dashboardSpecifique() {
    return (
      <Route
        path="/application"
        render={props => (
          <LowLevelDashboard
            {...props}
            mobile={this.state.mobile}
            handleApplicationChange={this.handleApplicationChange}
            account_list={this.state.account_list}
            handleEnvironmentChange={this.handleEnvironmentChange}
            environment={this.state.environment}
            selected_application={this.state.selected_application}
            user_name={this.state.userName}
            userIdToken={this.state.userIdToken}
          />
        )}
      />
    );
  }

  logsActions() {
    return (
      <Route
        path="/logs"
        render={() => <Logs userIdToken={this.state.userIdToken} />}
      />
    );
  }

  astreintesRoute() {
    return (
      <Route
        path="/astreintes"
        render={() => (
          <Astreintes
            userIdToken={this.state.userIdToken}
            user_name={this.state.userName}
          />
        )}
      />
    );
  }

  dashboardFacturation() {
    return (
      <Route
        path="/facturation"
        render={() => <BillingGeneral accountList={this.state.account_list} />}
      />
    );
  }

  dashboardPerimetres() {
    return (
      <Route
        path="/gestion-perimetres"
        render={props => (
          <PerimetersManagementGeneral
            {...props}
            accountList={this.state.account_list_full}
            userName={this.state.userName}
          />
        )}
      />
    );
  }

  pipelinesDashboard() {
    return (
      <Route
        path="/livraison/:app"
        render={({ match }) => (
          <PipelinesDashboard
            app={match.params.app}
            accountList={this.state.account_list}
            userName={this.state.userName}
          />
        )}
      />
    );
  }

  pipelineStatesDashboard() {
    return (
      <Route
        path="/pipeline/:pipeline_name"
        render={({ match, location }) => (
          <PipelineStatesDashboard
            pipelineName={match.params.pipeline_name}
            userName={this.state.userName}
            app={location.state.app}
            accountId={location.state.accountId}
          />
        )}
      />
    );
  }

  profilUtilisateur() {
    return (
      <Route
        path="/profil-utilisateur"
        render={props => (
          <ProfilUtilisateur {...props} userIdToken={this.state.userIdToken} />
        )}
      />
    );
  }

  userManagement() {
    return (
      <Route
        path="/gestion-utilisateurs"
        render={() => <UsersManagementGeneral userName={this.state.userName} />}
      />
    );
  }

  huntGroupsManagement() {
    return (
      <Route
        path="/gestion-hunt-group"
        render={() => (
          <HuntGroupsManagement
            classes={this.state.classes}
            userIdToken={this.state.userIdToken}
            userName={this.state.userName}
          />
        )}
      />
    );
  }

  noApps() {
    return (
      <Route path="/alerte-apps" render={props => <NoApps {...props} />} />
    );
  }

  renderSwitch(screenName) {
    switch (screenName) {
      case "Admin":
        return (
          <Switch>
            {this.dashboardGlobale()}
            {this.dashboardSpecifique()}
            {this.logsActions()}
            {this.astreintesRoute()}
            {this.dashboardFacturation()}
            {this.dashboardPerimetres()}
            {this.pipelinesDashboard()}
            {this.pipelineStatesDashboard()}
            {this.profilUtilisateur()}
            {this.userManagement()}
            {this.huntGroupsManagement()}
            {this.noApps()}
            <Redirect from="/" to="mes-applications" />
          </Switch>
        );

      case "dashboardGlobale":
        return (
          <Switch>
            {this.dashboardGlobale()}
            {this.profilUtilisateur()}
            {this.noApps()}
          </Switch>
        );

      case "dashboardSpecifique":
        return (
          <Switch>
            {this.dashboardSpecifique()}
            {this.profilUtilisateur()}
            {this.noApps()}
          </Switch>
        );

      case "logsActions":
        return (
          <Switch>
            {this.logsActions()}
            {this.profilUtilisateur()}
            {this.noApps()}
          </Switch>
        );

      default:
        return null;
    }
  }

  renderHome() {
    return (
      <div>
        {this.primarySearchAppBar()}
        <Router history={history}>
          {this.state.isAccountListFetched || this.state.noApps ? (
            <div className="content">
              {this.renderSwitch(this.state.currentScreen.toString())}
            </div>
          ) : (
            <CircularLoading />
          )}
        </Router>
      </div>
    );
  }

  render() {
    const { authState } = this.state;
    const isBrowserChromeOrSafari =
      navigator.userAgent.includes("Chrome") ||
      navigator.userAgent.includes("Safari") ||
      navigator.userAgent.includes("CriOS");
    return (
      <div className="App">
        {!isBrowserChromeOrSafari && (
          <BrowserError classes={this.state.classes} />
        )}
        {authState === "loading" && <div>chargement...</div>}
        {authState === "signIn" && <OAuthButton />}
        {authState === "signedIn" && this.renderHome()}
      </div>
    );
  }
}

export default App;
