import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import ErrorIcon from "@material-ui/icons/Error";
import { styles } from "../../Styles/ComponentStyles";

class BrowserError extends React.Component {
  constructor(props) {
    super(props);
    const { classes } = props;
    this.state = {
      classes: classes
    };
  }

  render() {
    return (
      <div className={this.state.classes.rootNoApps}>
        <Paper className={this.state.classes.rootNoApps} elevation={1}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <ErrorIcon color="error" fontSize="large" />
            <Typography variant="h5" component="h3">
              Veuillez utiliser Chrome ou Safari pour des conditions
              d'utilisation optimales
            </Typography>
          </div>
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles)(BrowserError);
