import React, { useEffect, useState } from "react";
import {
  computeAppBilling,
  getAccountBilling
} from "../../Services/AccountService";
import { CircularLoading, styles } from "../../Styles/ComponentStyles";
import BillingEnv from "./BillingEnv";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import { Button } from "@material-ui/core";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

const BillingApp = ({ appName, environments, classes }) => {
  const [environmentBillings, setEnvironmentBillings] = useState(undefined);
  const [appBilling, setAppBilling] = useState(undefined);
  const [displayEnvironments, setDisplayEnvironments] = useState(false);

  useEffect(() => {
    Promise.all(
      Object.entries(environments).map(([envName, accountId]) =>
        getAccountBilling(envName, accountId)
      )
    ).then(results =>
      setEnvironmentBillings(
        Object.fromEntries(
          results.map(result => [
            Object.keys(result)[0],
            Object.values(result)[0]
          ])
        )
      )
    );
  }, [environments]);

  useEffect(() => {
    if (environmentBillings) {
      setAppBilling(computeAppBilling(environmentBillings));
    }
  }, [environmentBillings]);

  return (
    <div>
      <div style={{ fontSize: "x-large", float: "left" }}>
        <b>{appName.toUpperCase()}</b>
      </div>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="center" style={{ fontSize: "large" }}>
              {" "}
              <Tooltip
                title={"Dépenses totales du mois dernier"}
                classes={{ tooltip: classes.lightTooltip }}
              >
                <b>Mois dernier ($)</b>
              </Tooltip>
            </TableCell>
            <TableCell align="center" style={{ fontSize: "large" }}>
              {" "}
              <Tooltip
                title={"Dépenses totales du mois en cours"}
                classes={{ tooltip: classes.lightTooltip }}
              >
                <b>Mois courant ($)</b>
              </Tooltip>
            </TableCell>
            <TableCell align="center" style={{ fontSize: "large" }}>
              {" "}
              <Tooltip
                title={
                  "Evolution des dépenses prévues pour le mois en cours par rapport au mois dernier"
                }
                classes={{ tooltip: classes.lightTooltip }}
              >
                <b>Prévision (%)</b>
              </Tooltip>
            </TableCell>
            <TableCell align="center" style={{ fontSize: "large" }}>
              <Button
                onClick={() => setDisplayEnvironments(!displayEnvironments)}
              >
                <b>
                  {displayEnvironments ? (
                    <ArrowDropDown fontSize="large" nativeColor="green" />
                  ) : (
                    <ArrowDropUp fontSize="large" nativeColor="green" />
                  )}
                </b>
              </Button>
            </TableCell>
          </TableRow>
        </TableHead>
        {environmentBillings && appBilling && (
          <TableBody>
            <BillingEnv
              key={"total"}
              envName={"TOTAL"}
              envBilling={appBilling}
            />
            {displayEnvironments &&
              Object.entries(
                environmentBillings
              ).map(([envName, envBilling]) => (
                <BillingEnv
                  key={envName}
                  envName={envName}
                  envBilling={envBilling}
                />
              ))}
          </TableBody>
        )}
      </Table>
      {!(environmentBillings && appBilling) && <CircularLoading />}
    </div>
  );
};

BillingApp.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(BillingApp);
