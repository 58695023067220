import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Amplify from "aws-amplify";

import { styles } from "../Styles/ScreenStyles";
import Button from "@material-ui/core/Button";
import ProfileDialog from "../Components/Dialogs/ProfileDialog";
import DoNotDisturbDialog from "../Components/Dialogs/DoNotDisturbDialog";
import { getUserProfile } from "../Services/UserProfilesService";

let pckg = require("../../package.json");

class ProfilUtilisateur extends React.Component {
  constructor(props) {
    super(props);
    const { classes } = props;
    this.state = {
      classes: classes,
      modalPhoneNumbersProfileOpen: false,
      modalDoNoDisturbProfileOpen: false
    };
  }

  gatherSessionInfo() {
    Amplify.Auth.currentSession().then(data => {
      getUserProfile(data.idToken.payload["cognito:username"]).then(
        responseUserProfile =>
          this.setState({
            userName: responseUserProfile.UserName,
            userFirstName: responseUserProfile.FirstName,
            userLastName: responseUserProfile.LastName,
            cognitoUserRole: data.idToken.payload["cognito:roles"]
          })
      );
    });
  }

  componentDidMount() {
    this.gatherSessionInfo();
  }

  handleEditPhoneNumberProfileClickOpen = () => {
    this.setState({ modalPhoneNumbersProfileOpen: true });
  };

  handleEditDoNotDisturbProfileClickOpen = () => {
    this.setState({ modalDoNoDisturbProfileOpen: true });
  };

  editDoNotDisturbProfileCallback = dialogState => {
    this.setState({ modalDoNoDisturbProfileOpen: dialogState });
  };

  editPhoneNumberProfileCallback = dialogState => {
    this.setState({ modalPhoneNumbersProfileOpen: dialogState });
  };

  renderEditDoNotDisturbProfileDialog = () => {
    if (this.state.modalDoNoDisturbProfileOpen) {
      return (
        <DoNotDisturbDialog
          userName={this.state.userName}
          editDoNotDisturbProfileCallback={this.editDoNotDisturbProfileCallback}
        />
      );
    }
  };

  renderEditPhoneNumberProfileDialog = () => {
    if (this.state.modalPhoneNumbersProfileOpen) {
      return (
        <ProfileDialog
          userName={this.state.userName}
          editPhoneNumberProfileCallback={this.editPhoneNumberProfileCallback}
        />
      );
    }
  };

  render() {
    return (
      <div className={this.state.classes.rootProfilUtilisateur}>
        <Paper
          className={this.state.classes.rootProfilUtilisateur}
          elevation={1}
        >
          <Typography variant="h5" component="h3">
            Vous êtes connecté en temps que: {this.state.userName} -{" "}
            {this.state.userFirstName} {this.state.userLastName}
          </Typography>
          <Typography component="p">
            Version de ExSI {pckg.version} ({process.env.REACT_APP_STAGE})
          </Typography>
        </Paper>

        <div>{this.renderEditPhoneNumberProfileDialog()}</div>
        <div>{this.renderEditDoNotDisturbProfileDialog()}</div>
        <div className="{this.state.classes.rootProfilUtilisateur}">
          <Button
            onClick={this.handleEditPhoneNumberProfileClickOpen}
            color="primary"
            key="add"
          >
            Gérer mes numéros de téléphone
          </Button>
          <Button
            onClick={this.handleEditDoNotDisturbProfileClickOpen}
            color="primary"
            key="do-not-disturb"
          >
            Gérer mon accessibilité
          </Button>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ProfilUtilisateur);
