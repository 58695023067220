import API from "@aws-amplify/api/lib";

export const retrieveAdmins = async requestedPage => {
  const apiResult = await API.get("api-exsi-backend-v2", "/admin", {
    queryStringParameters: {
      page: requestedPage
    }
  });
  return apiResult.split(",");
};
