import React from "react";
import { withSnackbar } from "notistack";
import { styles } from "../../Styles/ComponentStyles";
import { withStyles } from "@material-ui/core/styles";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import {
  getUserProfile,
  updateUserProfile
} from "../../Services/UserProfilesService";

class ProfileDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      form_tel_pro_error: true,
      form_tel_pro: "",
      form_error: true,
      user_name: this.props.userName,
      update_ok: true
    };
  }

  componentDidMount() {
    this.getProfileNumbers(this.state.userName);
  }

  closeModal = () => {
    this.props.editPhoneNumberProfileCallback(false);
  };

  textMatchesMethod(targetValue) {
    return /\+33[0-9]{9}$/g.test(targetValue);
  }

  handleTextFieldChangeFormTelPro = e => {
    if (this.textMatchesMethod(e.target.value)) {
      this.setState(
        {
          form_tel_pro_error: false
        },
        () => {
          this.updateGlobalFormError();
        }
      );
    } else {
      this.setState(
        {
          form_tel_pro_error: true
        },
        () => {
          this.updateGlobalFormError();
        }
      );
    }
    this.setState({
      form_tel_pro: e.target.value
    });
  };

  updateGlobalFormError() {
    if (this.state.form_tel_pro_error === true) {
      this.setState({
        form_error: true
      });
    } else {
      this.setState({
        form_error: false
      });
    }
  }

  getProfileNumbers = user_name => {
    this.setState(
      {
        getActualProfileInProgress: true
      },
      () => {
        getUserProfile(user_name)
          .then(response => {
            this.setState({
              form_tel_pro: "",
              form_tel_pro_error: true
            });
            if (response.hasOwnProperty("ProPhoneNumber")) {
              this.setState({
                form_tel_pro: response.ProPhoneNumber,
                form_tel_pro_error: !this.textMatchesMethod(
                  response.ProPhoneNumber
                )
              });
            }
          })
          .catch(error =>
            this.props.enqueueSnackbar(
              "Impossible de récupérer vos numéros de téléphone"
            )
          );
      }
    );
  };

  // Called when the form is validated
  handleValidateProfileDialog() {
    updateUserProfile(this.state.user_name, {
      ProPhoneNumber: this.state.form_tel_pro
    })
      .then(() => {
        this.props.enqueueSnackbar(
          "Vos numéros de téléphones ont été enregistrés"
        );
        this.closeModal();
      })
      .catch(error =>
        this.props.enqueueSnackbar(
          "Impossible d'enregistrer vos numéros de téléphone"
        )
      );
  }

  render() {
    const { classes } = this.props;

    return (
      <Dialog
        open={true}
        onClose={this.closeModal}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Gérer mes numéros de téléphone
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="form-alarm-name"
            label="Téléphone professionnel"
            type="text"
            error={this.state.form_tel_pro_error}
            value={this.state.form_tel_pro}
            onChange={this.handleTextFieldChangeFormTelPro}
            fullWidth
          />
          <FormHelperText id="component-helper-text">
            Un numéro de téléphone au format +33XXXXXXXXX
          </FormHelperText>

          <Grid container className={classes.grid} justify="space-around">
            <Button onClick={this.closeModal}>Annuler</Button>
            <Button
              onClick={() => this.handleValidateProfileDialog()}
              color="primary"
              disabled={this.state.form_error}
              key="add"
            >
              Enregistrer
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

export default withSnackbar(withStyles(styles)(ProfileDialog));
