import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { styles } from "../Styles/ScreenStyles";
import { CircularLoading } from "../Styles/ComponentStyles";
import HuntGroupsManagementTable from "../Components/Tables/HuntGroupsManagementTable";
import { retrieveAdmins } from "../Services/AdminService";
import Paper from "@material-ui/core/Paper";

class HuntGroupsManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      classes: this.props.classes,
      userIdToken: this.props.userIdToken,
      userName: this.props.userName,
      isAdminListFetch: false,
      adminsList: []
    };
  }

  componentDidMount() {
    retrieveAdmins("hunt_groups").then(result => {
      this.setState({
        adminsList: result,
        isAdminListFetch: true
      });
    });
  }

  render() {
    return this.state.isAdminListFetch ? (
      this.state.adminsList.indexOf(this.state.userName) > -1 ? (
        <div
          style={{ height: "90vh" }}
          className={this.state.classes.rootUserManagement}
        >
          <Paper
            className={this.state.classes.rootUserManagement}
            elevation={0}
          >
            <Typography variant="h5" component="h3">
              Gestion des hunt groups
            </Typography>
          </Paper>
          <Paper
            className={this.state.classes.rootUserManagement}
            elevation={1}
          >
            <HuntGroupsManagementTable
              classes={this.state.classes}
              userIdToken={this.state.userIdToken}
            ></HuntGroupsManagementTable>
          </Paper>
        </div>
      ) : (
        <Paper className={this.state.classes.rootUserManagement} elevation={1}>
          <Typography variant="h5" component="h3">
            Vous n'avez pas le droit d'accéder à cette page. Contacter un
            administrateur
          </Typography>
        </Paper>
      )
    ) : (
      <CircularLoading> </CircularLoading>
    );
  }
}

export default withStyles(styles)(HuntGroupsManagement);
