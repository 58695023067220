import React, { useState } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Button, Dialog } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import { CircularLoading } from "../../Styles/ComponentStyles";
import moment from "moment";
import { addLog } from "../../Services/LogsService";
import { getCurrentUserProfile } from "../../Services/AuthService";
import { deletePerimeter } from "../../Services/PerimetersService";

const PerimetersManagementDelete = ({
  open,
  onClose,
  onValidate,
  perimeterName
}) => {
  const [isDeleteInProgress, setIsDeleteInProgress] = useState(false);

  const handleClose = () => {
    onClose();
  };

  const handleValidate = async () => {
    setIsDeleteInProgress(true);
    await deletePerimeter(perimeterName);
    const currentUser = await getCurrentUserProfile();
    logDelete(currentUser);
    onValidate();
  };

  const logDelete = currentUser => {
    const currentTime = moment().unix();
    const actionDescription = "Suppression du périmètre " + perimeterName;
    addLog(currentUser.UserName, currentTime, actionDescription);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle
        style={{
          backgroundColor: "#3f51b5",
          textAlign: "center"
        }}
      >
        <div style={{ color: "white" }}>
          Vous êtes sur le point de supprimer le périmètre {perimeterName}.
          Êtes-vous sûr?
        </div>
      </DialogTitle>
      {isDeleteInProgress && <CircularLoading />}
      <DialogActions>
        <Button
          onClick={handleClose}
          variant={"contained"}
          disabled={isDeleteInProgress}
        >
          <div
            style={{
              color: "white"
            }}
          >
            Annuler
          </div>
        </Button>
        <Button
          onClick={handleValidate}
          variant={"contained"}
          style={{
            backgroundColor: "#32CD32"
          }}
          disabled={isDeleteInProgress}
        >
          <div
            style={{
              color: "white"
            }}
          >
            Valider
          </div>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PerimetersManagementDelete;
