import React, { useEffect, useState } from "react";
import PipelineStage from "./PipelineStage";
import {
  getPipelineExecutions,
  getPipelineState,
  retrieveRelevantPipelineExecutions
} from "../../Services/PipelinesService";
import { CircularLoading } from "../../Styles/ComponentStyles";
import { Button } from "@material-ui/core";
import ReplayIcon from "@material-ui/icons/Replay";

const PipelineStatesDashboard = ({
  pipelineName,
  app,
  userName,
  accountId
}) => {
  const [stageStatesFetched, setStageStatesFetched] = useState(undefined);
  const [allExecutionsFetched, setAllExecutionsFetched] = useState(undefined);

  useEffect(() => {
    const fetchPipelineState = async () => {
      if (!stageStatesFetched) {
        const state = await getPipelineState(pipelineName, accountId);
        setStageStatesFetched(state.stageStates);
      }
    };

    fetchPipelineState();
  }, [pipelineName, stageStatesFetched, setStageStatesFetched, accountId]);

  useEffect(() => {
    const fetchPipelineExecutions = async () => {
      if (!allExecutionsFetched) {
        const executions = await getPipelineExecutions(pipelineName, accountId);
        setAllExecutionsFetched(executions.pipelineExecutionSummaries);
      }
    };

    fetchPipelineExecutions();
  }, [pipelineName, allExecutionsFetched, setAllExecutionsFetched, accountId]);

  return (
    <div>
      <h1>
        {pipelineName}{" "}
        <Button
          size="small"
          variant={"contained"}
          color={"primary"}
          key={"button_refresh_page"}
          onClick={() => {
            setAllExecutionsFetched(undefined);
            setStageStatesFetched(undefined);
          }}
        >
          <ReplayIcon />
        </Button>
      </h1>
      <div
        style={{
          display: "flex",
          justifyContent: "spaceAround",
          flexWrap: "wrap"
        }}
      >
        {stageStatesFetched && allExecutionsFetched ? (
          stageStatesFetched.map((stageState, index) => (
            <PipelineStage
              key={index}
              pipelineName={pipelineName}
              stageState={stageState}
              lastTwoExecutions={retrieveRelevantPipelineExecutions(
                allExecutionsFetched,
                stageState.latestExecution &&
                  stageState.latestExecution.pipelineExecutionId
                  ? stageState.latestExecution.pipelineExecutionId
                  : undefined
              )}
              userName={userName}
              app={app}
              accountId={accountId}
            />
          ))
        ) : (
          <CircularLoading />
        )}
      </div>
    </div>
  );
};

export default PipelineStatesDashboard;
