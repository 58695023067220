import API from "@aws-amplify/api/lib";

export const groupAccountsByApp = accountList => {
  const sortedAppList = [
    ...new Set(
      Object.entries(accountList).map(
        ([accountName]) => accountName.split("-")[0]
      )
    )
  ].sort();
  return Object.fromEntries(
    sortedAppList.map(appName => [
      appName,
      Object.fromEntries(
        Object.keys(accountList)
          .sort(sortEnv)
          .filter(accountName => accountName.includes(appName))
          .map(accountName => [
            accountName.split("-")[1],
            accountList[accountName]
          ])
      )
    ])
  );
};

export const getAccountBilling = async (envName, accountId) => {
  const billing = await API.get(
    "api-exsi-backend",
    "/accounts/" + accountId + "/billing",
    {}
  );
  return {
    [envName]: billing
  };
};

export const getAllAccounts = () =>
  API.get("api-exsi-backend", "/accounts", {});

export const getAccountAlarmLogs = accountId =>
  API.get("api-exsi-backend", "/accounts/" + accountId + "/alarmLogs", {});

export const getAccountState = accountId =>
  API.get("api-exsi-backend", "/accounts/" + accountId + "/state", {});

export const putAccountState = (accountId, state) =>
  API.put("api-exsi-backend", "/accounts/" + accountId + "/state", {
    body: { state }
  });

export const computeAppBilling = envBillings => {
  const reducedBilling = [
    ...Object.keys(envBillings).map(env => envBillings[env])
  ].reduce((accumulator, item) => {
    Object.keys(item).forEach(key => {
      if (key !== "forecastEvolution") {
        accumulator[key] = (accumulator[key] || 0) + item[key];
      }
    });
    return accumulator;
  }, {});
  return {
    previousMonth: reducedBilling["previousMonth"],
    monthToDate: reducedBilling["monthToDate"],
    forecastEvolution: !isNaN(reducedBilling["forecast"])
      ? parseFloat(
          (
            100 -
            (reducedBilling["previousMonth"] * 100) / reducedBilling["forecast"]
          ).toFixed(1)
        )
      : "N/A"
  };
};

const sortEnv = (accountNameFirst, accountNameSecond) => {
  const envFirst = accountNameFirst.split("-")[1];
  const envSecond = accountNameSecond.split("-")[1];
  if (envFirst === "dev" && (envSecond === "stg" || envSecond === "prd")) {
    return -1;
  }
  if (envFirst === "prd" && (envSecond === "stg" || envSecond === "dev")) {
    return 1;
  }
  if (envFirst === "stg" && envSecond === "dev") {
    return 1;
  }
  return -1;
};
